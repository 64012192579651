import { createLogic } from "redux-logic";

import {
  loginFailed,
  AuthActions,
  userDetailsSuccess,
  redirectTo,
  changePasswordSuccess,
  changePasswordFailed,
  getUserDetails,
  requestLogout,
  userDetailsFailed,
  userSignupSuccess,
  userSignupFailed,
  linkVerificationSuccess,
  linkVerificationFailed,
  setPasswordSuccess,
  setPasswordFailed,
  passwordLinkVerificationSuccess,
  passwordLinkVerificationFailed,
  updateNotificationFailed,
  updateNotificationSuccess,
  addCardSuccess,
  addCardFailed,
  cardListFailed,
  cardListSuccess,
  deleteCardFailed,
  requestCardList,
  updateCardStatusFailed,
  forgotPasswordSuccess,
  forgotPasswordFailed,
  inviteUserListSuccess,
  inviteUserListFailed,
  inviteUserFailed,
  inviteUserSuccess,
  removeUserFailed,
  requestInviteUserList,
  subscriptionListFailed,
  subscriptionListSuccess,
  cancelSubscriptionFailed,
  requestSubscriptionList,
  cancelSubscriptionSuccess,
  guestDataSuccess,
  guestDataFailed,
} from "../actions";
import { ApiHelper } from "../../helpers";
import { showSuccess, showError } from "../../helpers/Toast";
import ApiRoutes from "config/ApiRoutes";
import { AppRoutes } from "config";
import { setToken, removeToken } from "../../common/Token";

const loginLogic = createLogic({
  type: AuthActions.REQUEST_LOGIN,
  async process({ action }, dispatch, done) {
    const { email = "", password = "" } = action.payload || {};
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LOGIN.service,
      ApiRoutes.LOGIN.url,
      ApiRoutes.LOGIN.method,
      ApiRoutes.LOGIN.authenticate,
      undefined,
      { email, password }
    );
    if (!isError) {
      setToken(responseData.data.token);
    //  dispatch(userDetailsSuccess({ user: responseData.data }));
      dispatch(getUserDetails());
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(loginFailed(messages[0]));
      done();
    }
  },
});

const signupLogic = createLogic({
  type: AuthActions.USER_SIGNUP_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.SIGNUP.service,
      ApiRoutes.SIGNUP.url,
      ApiRoutes.SIGNUP.method,
      ApiRoutes.SIGNUP.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(userSignupSuccess(responseData.data));
      dispatch(redirectTo({ path: AppRoutes.LOGIN }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(userSignupFailed(messages[0]));
      done();
    }
  },
});

const linkVerifyLogic = createLogic({
  type: AuthActions.LINK_VERIFICATION_REQUEST,
  async process({ action }, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY.service,
      ApiRoutes.VERIFY.url.replace(":id", action.payload),
      ApiRoutes.VERIFY.method,
      ApiRoutes.VERIFY.authenticate,
      undefined,
      action.payload
    );

    if (!isError) {
      dispatch(linkVerificationSuccess());
      dispatch(redirectTo({ path: AppRoutes.LOGIN }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(linkVerificationFailed(messages[0]));
      done();
    }
  },
});

const forgotPasswordLogic = createLogic({
  type: AuthActions.REQUEST_FORGOT_PASSWORD,
  async process({ action }, dispatch, done) {
    const { email = "" } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.FORGOT_PASSWORD.service,
      ApiRoutes.FORGOT_PASSWORD.url,
      ApiRoutes.FORGOT_PASSWORD.method,
      ApiRoutes.FORGOT_PASSWORD.authenticate,
      undefined,
      { email }
    );
    if (!isError) {
      showSuccess(messages[0]);
      dispatch(forgotPasswordSuccess());
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      done();
    } else {
      showError(messages[0]);
      dispatch(forgotPasswordFailed());
      dispatch(loginFailed(messages[0]));
      done();
    }
  },
});

const passwordLinkVerificationLogic = createLogic({
  type: AuthActions.LINK_VERIFICATION_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VERIFY_LINK.service,
      ApiRoutes.VERIFY_LINK.url,
      ApiRoutes.VERIFY_LINK.method,
      ApiRoutes.VERIFY_LINK.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(passwordLinkVerificationSuccess(responseData.data));
      done();
    } else {
      dispatch(
        passwordLinkVerificationFailed({
          message: messages[0],
        })
      );
      done();
    }
    return;
  },
});

const setPasswordLogic = createLogic({
  type: AuthActions.SET_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const { forgetPasswordToken = "" } = action.payload || {};
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.SET_PASSWORD.service,
      ApiRoutes.SET_PASSWORD.url.replace(":id", forgetPasswordToken),
      ApiRoutes.SET_PASSWORD.method,
      ApiRoutes.SET_PASSWORD.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(setPasswordSuccess(responseData));
      showSuccess(messages[0]);
      done();
    } else {
      dispatch(
        setPasswordFailed({
          message: messages[0],
        })
      );
      showError(messages[0]);
      done();
    }
    return;
  },
});

const getUserLogic = createLogic({
  type: AuthActions.GET_USER_DETAILS,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PROFILE.service,
      ApiRoutes.GET_PROFILE.url,
      ApiRoutes.GET_PROFILE.method,
      ApiRoutes.GET_PROFILE.authenticate,
      undefined,
      undefined
    );
    if (!isError) {
      dispatch(
        userDetailsSuccess({
          user: responseData.data,
        })
      );
      done();
    } else {
      dispatch(redirectTo({ path: AppRoutes.LOGIN }));
      removeToken();
      dispatch(userDetailsFailed(messages[0]));
      done();
    }
  },
});

const changePasswordLogic = createLogic({
  type: AuthActions.CHANGE_PASSWORD_REQUEST,
  async process({ action }, dispatch, done) {
    const { oldPassword = "", password = "" } = action.payload || {};
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHANGE_PASSWORD.service,
      ApiRoutes.CHANGE_PASSWORD.url,
      ApiRoutes.CHANGE_PASSWORD.method,
      ApiRoutes.CHANGE_PASSWORD.authenticate,
      undefined,
      { oldPassword, newPassword: password }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(changePasswordFailed());
      done();
    } else {
      showSuccess("Password updated successfully");
      dispatch(changePasswordSuccess());
      dispatch(requestLogout());
      done();
    }
  },
});

const updateProfileLogic = createLogic({
  type: AuthActions.REQUEST_UPDATE_PROFILE,
  async process({ action }, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PROFILE.service,
      ApiRoutes.UPDATE_PROFILE.url,
      ApiRoutes.UPDATE_PROFILE.method,
      ApiRoutes.UPDATE_PROFILE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      showSuccess(messages[0]);
      dispatch(getUserDetails());
      done();
    } else {
      showError(messages[0]);
      done();
    }
  },
});

const logoutLogic = createLogic({
  type: AuthActions.LOGOUT_REQUEST,

  async process({ action }, dispatch, done) {
    removeToken();
    //window.location.reload();
    dispatch(redirectTo({ path: AppRoutes.HOME }));

    done();
  },
});

/**
 *
 */
const updateNotificationLogic = createLogic({
  type: AuthActions.UPDATE_NOTIFICATION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_NOTIFICATION.service,
      ApiRoutes.UPDATE_NOTIFICATION.url,
      ApiRoutes.UPDATE_NOTIFICATION.method,
      ApiRoutes.UPDATE_NOTIFICATION.authenticate,
      undefined,
      { isActiveNotification: action.payload.isActiveNotification }
    );
    if (isError) {
      dispatch(updateNotificationFailed(messages[0]));
      done();
    } else {
      dispatch(updateNotificationSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const addCardLogic = createLogic({
  type: AuthActions.REQUEST_ADD_CARD,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_CARD.service,
      ApiRoutes.ADD_CARD.url,
      ApiRoutes.ADD_CARD.method,
      ApiRoutes.ADD_CARD.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addCardSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addCardFailed());
      done();
    }
  },
});

/**
 *
 */
const getCardsLogic = createLogic({
  type: AuthActions.REQUEST_CARD_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CARDS.service,
      ApiRoutes.GET_CARDS.url,
      ApiRoutes.GET_CARDS.method,
      ApiRoutes.GET_CARDS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(cardListFailed());
      done();
    } else {
      dispatch(
        cardListSuccess({
          cards: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const deleteCardLogic = createLogic({
  type: AuthActions.DELETE_CARD_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_CARD.service,
      ApiRoutes.DELETE_CARD.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_CARD.method,
      ApiRoutes.DELETE_CARD.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(deleteCardFailed(messages[0]));
      done();
    } else {
      dispatch(requestCardList());
      dispatch(messages[0]);
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const updateCardStatusLogic = createLogic({
  type: AuthActions.UPDATE_CARD_STATUS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_CARD_STATUS.service,
      ApiRoutes.UPDATE_CARD_STATUS.url,
      ApiRoutes.UPDATE_CARD_STATUS.method,
      ApiRoutes.UPDATE_CARD_STATUS.authenticate,
      undefined,
      { stripe_card_id: action.payload }
    );
    if (isError) {
      dispatch(updateCardStatusFailed(messages[0]));
      done();
    } else {
      dispatch(requestCardList());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const inviteUserLogic = createLogic({
  type: AuthActions.REQUEST_INVITE_USER,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.INVITE_USER.service,
      ApiRoutes.INVITE_USER.url,
      ApiRoutes.INVITE_USER.method,
      ApiRoutes.INVITE_USER.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(inviteUserSuccess());
      dispatch(requestInviteUserList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(inviteUserFailed());
      done();
    }
  },
});

/**
 *
 */
const getInviteUsersLogic = createLogic({
  type: AuthActions.REQUEST_INVITE_USER_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_INVITE_USER.service,
      ApiRoutes.GET_INVITE_USER.url,
      ApiRoutes.GET_INVITE_USER.method,
      ApiRoutes.GET_INVITE_USER.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(inviteUserListFailed());
      done();
    } else {
      dispatch(
        inviteUserListSuccess({
          inviteUsers: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const removeUserLogic = createLogic({
  type: AuthActions.REMOVE_USER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.REMOVE_USER.service,
      ApiRoutes.REMOVE_USER.url.replace(":id", action.payload.id),
      ApiRoutes.REMOVE_USER.method,
      ApiRoutes.REMOVE_USER.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(removeUserFailed(messages[0]));
      done();
    } else {
      dispatch(requestInviteUserList());
      dispatch(messages[0]);
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const reInviteUserLogic = createLogic({
  type: AuthActions.RE_INVITE_USER_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.RE_INVITE_USER.service,
      ApiRoutes.RE_INVITE_USER.url.replace(":id", action.payload.id),
      ApiRoutes.RE_INVITE_USER.method,
      ApiRoutes.RE_INVITE_USER.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      done();
    } else {
      dispatch(requestInviteUserList());
      dispatch(messages[0]);
      showSuccess(messages[0]);
      done();
    }
  },
});

const getSubscriptionsLogic = createLogic({
  type: AuthActions.REQUEST_SUBSCRIPTION_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SUBSCRIPTIONS.service,
      ApiRoutes.GET_SUBSCRIPTIONS.url,
      ApiRoutes.GET_SUBSCRIPTIONS.method,
      ApiRoutes.GET_SUBSCRIPTIONS.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(subscriptionListFailed());
      done();
    } else {
      dispatch(
        subscriptionListSuccess({
          data: responseData.data,
        })
      );
      done();
    }
  },
});

const cancelSubscriptionLogic = createLogic({
  type: AuthActions.CANCEL_SUBSCRIPTION_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.CANCEL_SUBSCRIPTION.service,
      ApiRoutes.CANCEL_SUBSCRIPTION.url,
      ApiRoutes.CANCEL_SUBSCRIPTION.method,
      ApiRoutes.CANCEL_SUBSCRIPTION.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      dispatch(cancelSubscriptionFailed(messages[0]));
      done();
    }
    dispatch(requestSubscriptionList());
    dispatch(cancelSubscriptionSuccess());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

const getGuestLogic = createLogic({
  type: AuthActions.GET_GUEST_DATA,
  async process({ action }, dispatch, done) {
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_GUESTDATA.service,
      ApiRoutes.GET_GUESTDATA.url,
      ApiRoutes.GET_GUESTDATA.method,
      ApiRoutes.GET_GUESTDATA.authenticate,
      undefined,
      undefined
    );
    if (!isError) {
      dispatch(
        guestDataSuccess({
          guestData: responseData.data,
        })
      );
      done();
    } else {
      dispatch(guestDataFailed(messages[0]));
      done();
    }
  },
});

export const AuthLogics = [
  getGuestLogic,
  loginLogic,
  logoutLogic,
  signupLogic,
  linkVerifyLogic,
  forgotPasswordLogic,
  setPasswordLogic,
  passwordLinkVerificationLogic,
  getUserLogic,
  changePasswordLogic,
  updateProfileLogic,
  updateNotificationLogic,
  addCardLogic,
  getCardsLogic,
  deleteCardLogic,
  updateCardStatusLogic,
  inviteUserLogic,
  getInviteUsersLogic,
  removeUserLogic,
  getSubscriptionsLogic,
  cancelSubscriptionLogic,
  reInviteUserLogic,
];
