/** @format */

export const SavedSearchInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  error: false,
  savedSearch: {},

  isLoadingSearch: false,
  isSubmittingSearch: false,
  isAddedSearch: false,
  searchIdvalue: "",
  searchData: [],
};
