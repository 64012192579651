import Cookies from 'universal-cookie';
const cookies = new Cookies();
const domain = process.env.VERCEL_ENV === "preview" ? process.env.VERCEL_BRANCH_URL : process.env.REACT_APP_COOKIE_DOMAIN; 


const getToken = () => {
    //   return localStorage.getItem("token");
    const token = cookies.get('token', { domain, path: '/' });
    return token ? token : "";
};

const setToken = (data) => {
    //   return localStorage.getItem("token");
    cookies.set('token', data, { domain, path: '/' });
    return "";
};

const removeToken = () => {
    //   return localStorage.removeItem("token");
    cookies.remove('token', { domain, path: '/' });
    // const token = cookies.set('token', "", { domain: process.env.REACT_APP_COOKIE_DOMAIN, path: '/' });
    return "";
};

export {
    getToken,
    setToken,
    removeToken,
};
