/** @format */

import { handleActions } from "redux-actions";
import { PipelineActions } from "../actions";
import { PipelineInitialStates } from "../states";

export const PipelineReducer = handleActions(
  {
    [PipelineActions.REQUEST_ADD_PIPELINE]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),

    [PipelineActions.ADD_PIPELINE_SUCCESS]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),

    [PipelineActions.ADD_PIPELINE_FAILURE]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [PipelineActions.REQUEST_PIPELINES_LIST]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      pipelines: [],
    }),
    [PipelineActions.PIPELINES_LIST_SUCCESS]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      pipelines: action.payload.pipelines,
      isEmpty: action.payload.isEmpty,
    }),
    [PipelineActions.PIPELINES_LIST_FAILURE]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [PipelineActions.UPDATE_PIPELINE_REQUEST]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [PipelineActions.UPDATE_PIPELINE_SUCCESS]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [PipelineActions.UPDATE_PIPELINE_FAILURE]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),
    [PipelineActions.VIEW_PIPELINE_REQUEST]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [PipelineActions.VIEW_PIPELINE_SUCCESS]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      pipelineData: action.payload.pipelineData,
    }),
    [PipelineActions.VIEW_PIPELINE_FAILURE]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [PipelineActions.EXPORT_CSV_PIPELINE_REQUEST]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [PipelineActions.EXPORT_CSV_PIPELINE_SUCCESS]: (
      state = PipelineInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),

    // [PipelineActions.DELETE_STAGE_OPPORTUNITY_REQUEST]: (
    //   state = PipelineInitialStates,
    //   action
    // ) => ({
    //   ...state,
    //   // isLoading: true,
    //   isUpdated: false,
    // }),
    // [PipelineActions.DELETE_STAGE_OPPORTUNITY_SUCCESS]: (
    //   state = PipelineInitialStates,
    //   action
    // ) => ({
    //   ...state,
    //   // isLoading: false,
    //   isUpdated: true,
    // }),
  },
  PipelineInitialStates
);
