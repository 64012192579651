/** @format */

import { handleActions } from "redux-actions";
import { NaicsActions } from "../actions";
import { NaicsInitialStates } from "../states";

export const NaicsReducer = handleActions(
  {
    [NaicsActions.REQUEST_NAICS_LIST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      naics: [],
    }),
    [NaicsActions.NAICS_LIST_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      naics: action.payload.naics,
      isEmpty: action.payload.isEmpty,
      totalRecords: action.payload.totalRecords,
    }),
    [NaicsActions.NAICS_LIST_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [NaicsActions.VIEW_NAICS_REQUEST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      naicsData: {},
    }),
    [NaicsActions.VIEW_NAICS_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      naicsData: action.payload.naicsData,
    }),
    [NaicsActions.VIEW_NAICS_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [NaicsActions.EXPORT_CSV_NAICS_REQUEST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [NaicsActions.EXPORT_CSV_NAICS_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [NaicsActions.EXPORT_CSV_NAICS_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),


    [NaicsActions.EXPORT_VIEW_NAICS_REQUEST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
      viewNaics: [],
    }),
    [NaicsActions.EXPORT_VIEW_NAICS_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      viewNaics: action.payload.viewNaics,
      totalRecords: action.payload.totalRecords,
    }),
    [NaicsActions.EXPORT_VIEW_NAICS_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),

    [NaicsActions.REQUEST_NAICS_HIERARCHY_LIST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isNaicsHierarchyLoading: true,
      naicsHierarchy: {},
    }),
    [NaicsActions.NAICS_HIERARCHY_LIST_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isNaicsHierarchyLoading: false,
      naicsHierarchy: action.payload.naicsHierarchy,
    }),
    [NaicsActions.NAICS_HIERARCHY_LIST_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isNaicsHierarchyLoading: false,
      error: true,
    }),

    [NaicsActions.REQUEST_FILTER_PARENT_NAICS_LIST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoadingparentNaisc: true,
      parentNaicsList: [],
    }),
    [NaicsActions.FILTER_PARENT_NAICS_LIST_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoadingparentNaisc: false,
      parentNaicsList: action.payload.parentNaicsList,
    }),
    [NaicsActions.FILTER_PARENT_NAICS_LIST_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isLoadingparentNaisc: false,
      error: true,
    }),

    [NaicsActions.REQUEST_NAICS_FUNDING_ANALYSIS_LIST]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: true,
      fundingData: [],
      fundingGraphData: [],
    }),
    [NaicsActions.NAICS_FUNDING_ANALYSIS_LIST_SUCCESS]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      fundingData: action.payload.fundingData,
      fundingGraphData: action.payload.fundingGraphData,
      totalDollarsObligated: action.payload.totalDollarsObligated,
    }),
    [NaicsActions.NAICS_FUNDING_ANALYSIS_LIST_FAILURE]: (
      state = NaicsInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      error: true,
    }),
  },
  NaicsInitialStates
);
