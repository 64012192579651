/** @format */

import { createAction } from "redux-actions";

export const SavedSearchActions = {
  REQUEST_ADD_SAVED_SEARCH: "Request to add saved Search",
  ADD_SAVED_SEARCH_SUCCESS: "SavedSearch added successfully",
  ADD_SAVED_SEARCH_FAILURE: "Failed to add saved Search",

  REQUEST_SAVED_SEARCH_LIST: "Request to fetch saved Search",
  SAVED_SEARCH_LIST_SUCCESS: "SavedSearch fetched successfully",
  SAVED_SEARCH_LIST_FAILURE: "Failed to fetched saved Search",

  UPDATE_SAVED_SEARCH_REQUEST: "Request to update saved Search",
  UPDATE_SAVED_SEARCH_SUCCESS: "SavedSearch updated successfully",
  UPDATE_SAVED_SEARCH_FAILURE: "Failed to update saved Search",

  DELETE_SAVED_SEARCH_REQUEST: "Request to delete saved Search",
  DELETE_SAVED_SEARCH_FAILURE: "Failed to delete saved Search",

  REQUEST_ADD_SEARCH: "Request to add  search",
  ADD_SEARCH_SUCCESS: "Search added successfully",
  ADD_SEARCH_FAILURE: "Failed to add search",

  REQUEST_SEARCH_LIST: "Request to fetch searchs",
  SEARCH_LIST_SUCCESS: "Search fetched successfully",
  SEARCH_LIST_FAILURE: "Failed to fetched searchs",

  UPDATE_SEARCH_REQUEST: "Request to update search",
  UPDATE_SEARCH_SUCCESS: "Search updated successfully",
  UPDATE_SEARCH_FAILURE: "Failed to update search",

  RESET_SEARCH_ID_VALUE: "Request to reset serach id value",
  RESET_SEARCH_VALUE: "Request to reset serach value",
};

// Add saved Search actions
export const requestAddSavedSearch = createAction(
  SavedSearchActions.REQUEST_ADD_SAVED_SEARCH
);
export const addSavedSearchSuccess = createAction(
  SavedSearchActions.ADD_SAVED_SEARCH_SUCCESS
);
export const addSavedSearchFailed = createAction(
  SavedSearchActions.ADD_SAVED_SEARCH_FAILURE
);

// List saved Search actions
export const requestSavedSearchList = createAction(
  SavedSearchActions.REQUEST_SAVED_SEARCH_LIST
);
export const savedSearchListSuccess = createAction(
  SavedSearchActions.SAVED_SEARCH_LIST_SUCCESS
);
export const savedSearchListFailed = createAction(
  SavedSearchActions.SAVED_SEARCH_LIST_FAILURE
);

// Update saved Search actions
export const updateSavedSearchRequest = createAction(
  SavedSearchActions.UPDATE_SAVED_SEARCH_REQUEST
);
export const updateSavedSearchSuccess = createAction(
  SavedSearchActions.UPDATE_SAVED_SEARCH_SUCCESS
);
export const updateSavedSearchFailed = createAction(
  SavedSearchActions.UPDATE_SAVED_SEARCH_FAILURE
);

// Delete saved Search actions
export const deleteSavedSearchRequest = createAction(
  SavedSearchActions.DELETE_SAVED_SEARCH_REQUEST
);
export const deleteSavedSearchFailed = createAction(
  SavedSearchActions.DELETE_SAVED_SEARCH_FAILURE
);

// Add Search actions
export const requestAddSearch = createAction(
  SavedSearchActions.REQUEST_ADD_SEARCH
);
export const addSearchSuccess = createAction(
  SavedSearchActions.ADD_SEARCH_SUCCESS
);
export const addSearchFailed = createAction(
  SavedSearchActions.ADD_SEARCH_FAILURE
);

// List Search actions
export const requestSearchList = createAction(
  SavedSearchActions.REQUEST_SEARCH_LIST
);
export const SearchListSuccess = createAction(
  SavedSearchActions.SEARCH_LIST_SUCCESS
);
export const SearchListFailed = createAction(
  SavedSearchActions.SEARCH_LIST_FAILURE
);

// Update Search actions
export const updateSearchRequest = createAction(
  SavedSearchActions.UPDATE_SEARCH_REQUEST
);
export const updateSearchSuccess = createAction(
  SavedSearchActions.UPDATE_SEARCH_SUCCESS
);
export const updateSearchFailed = createAction(
  SavedSearchActions.UPDATE_SEARCH_FAILURE
);

export const resetSeachIdRequest = createAction(
  SavedSearchActions.RESET_SEARCH_ID_VALUE
);

export const resetSeachRequest = createAction(
  SavedSearchActions.RESET_SEARCH_VALUE
);
