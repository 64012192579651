/** @format */

export const FederalAgenciesInitialStates = {
  isLoading: false,
  federalagencies: [],
  federalagenciesData: {},
  isDownloaded: false,

  isHierarchyLoading: false,
  federalHierarchy: [],

  isLoadingParentAgency: false,
  parentAgencyList: [],

  isfundingLoading: false,
  fundingData: [],
  totalDollarsObligated: "",
  fundingGraphData: [],

  viewFederalAgencies: [],
  isExported: false,
};
