/** @format */

import { handleActions } from "redux-actions";
import { GlobalSearchActions } from "../actions";
import { GlobalSearchInitialStates } from "../states";

export const GlobalSearchReducer = handleActions(
  {
    [GlobalSearchActions.GLOBAL_SEARCH_REQUEST]: (
      state = GlobalSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [GlobalSearchActions.GLOBAL_SEARCH_SUCCESS]: (
      state = GlobalSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      globalSearchData: action.payload.globalSearchData,
    }),
    [GlobalSearchActions.GLOBAL_SEARCH_FAILURE]: (
      state = GlobalSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
  },
  GlobalSearchInitialStates
);
