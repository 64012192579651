import { createAction } from "redux-actions";

export const FederalAgenciesActions = {
  REQUEST_FEDERALAGENCIES_LIST: "Request to fetch FederalAgencies list",
  FEDERALAGENCIES_LIST_SUCCESS: "FederalAgencies list fetched successfully",
  FEDERALAGENCIES_LIST_FAILURE: "Failed to fetched FederalAgencies list",

  VIEW_FEDERALAGENCIES_REQUEST: "Request to view FederalAgencies",
  VIEW_FEDERALAGENCIES_SUCCESS: "FederalAgencies details fetched successfully",
  VIEW_FEDERALAGENCIES_FAILURE: "Failed to fetch federalagencies details",

  EXPORT_CSV_FEDERALAGENCIES_REQUEST: "Request to export csv federalagencies",
  EXPORT_CSV_FEDERALAGENCIES_SUCCESS: "federalagencies export successfully",
  EXPORT_CSV_FEDERALAGENCIES_FAILURE: "federalagencies export Failed",

  EXPORT_VIEW_FEDERALAGENCIES_REQUEST: "Request to export view federalagencies",
  EXPORT_VIEW_FEDERALAGENCIES_SUCCESS: "federalagencies export view successfully",
  EXPORT_VIEW_FEDERALAGENCIES_FAILURE: "federalagencies export view Failed",

  REQUEST_FEDERAL_HIERARCHY: "Request to fetch Federal Hierarchy list",
  FEDERAL_HIERARCHY_SUCCESS: "Federal Hierarchy list fetched successfully",
  FEDERAL_HIERARCHY_FAILURE: "Failed to fetched Federal Hierarchy list",

  REQUEST_FILTER_PARENT_AGENCY_LIST:
    "Request to fetch filter parent agency list",
  FILTER_PARENT_AGENCY_LIST_SUCCESS:
    "Filter parent agency  list fetched successfully",
  FILTER_PARENT_AGENCY_LIST_FAILURE:
    "Failed to fetched filter parent agency  list",

  REQUEST_AGENCY_FUNDING_ANALYSIS_LIST:
    "Request to fetch Funding Analysis list",
  AGENCY_FUNDING_ANALYSIS_LIST_SUCCESS:
    "Funding Analysis list fetched successfully",
  AGENCY_FUNDING_ANALYSIS_LIST_FAILURE:
    "Failed to fetched Funding Analysis list",
};

// List opportunity actions
export const requestFederalAgenciesList = createAction(
  FederalAgenciesActions.REQUEST_FEDERALAGENCIES_LIST
);
export const federalagenciesListSuccess = createAction(
  FederalAgenciesActions.FEDERALAGENCIES_LIST_SUCCESS
);
export const federalagenciesListFailed = createAction(
  FederalAgenciesActions.FEDERALAGENCIES_LIST_FAILURE
);

// View opportunity actions
export const viewFederalAgenciesRequest = createAction(
  FederalAgenciesActions.VIEW_FEDERALAGENCIES_REQUEST
);
export const viewFederalAgenciesSuccess = createAction(
  FederalAgenciesActions.VIEW_FEDERALAGENCIES_SUCCESS
);
export const viewFederalAgenciesFailed = createAction(
  FederalAgenciesActions.VIEW_FEDERALAGENCIES_FAILURE
);

export const exportFederalAgenciesRequest = createAction(
  FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_REQUEST
);
export const exportFederalAgenciesSuccess = createAction(
  FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_SUCCESS
);
export const exportFederalAgenciesFailed = createAction(
  FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_FAILURE
);

export const exportViewFederalAgenciesRequest = createAction(
  FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_REQUEST
);
export const exportViewFederalAgenciesSuccess = createAction(
  FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_SUCCESS
);
export const exportViewFederalAgenciesFailed = createAction(
  FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_FAILURE
);

// List opportunity actions
export const requestFederalHierarchy = createAction(
  FederalAgenciesActions.REQUEST_FEDERAL_HIERARCHY
);
export const federalHierarchySuccess = createAction(
  FederalAgenciesActions.FEDERAL_HIERARCHY_SUCCESS
);
export const federalHierarchyFailed = createAction(
  FederalAgenciesActions.FEDERAL_HIERARCHY_FAILURE
);

export const requestParentAgencyList = createAction(
  FederalAgenciesActions.REQUEST_FILTER_PARENT_AGENCY_LIST
);
export const parentAgencyListSuccess = createAction(
  FederalAgenciesActions.FILTER_PARENT_AGENCY_LIST_SUCCESS
);
export const parentAgencyListFailed = createAction(
  FederalAgenciesActions.FILTER_PARENT_AGENCY_LIST_FAILURE
);

// FundingAnalysis

export const requestAgencyFundingAnalysisList = createAction(
  FederalAgenciesActions.REQUEST_AGENCY_FUNDING_ANALYSIS_LIST
);
export const agencyFundingAnalysisListSuccess = createAction(
  FederalAgenciesActions.AGENCY_FUNDING_ANALYSIS_LIST_SUCCESS
);
export const agencyFundingAnalysisListFailed = createAction(
  FederalAgenciesActions.AGENCY_FUNDING_ANALYSIS_LIST_FAILURE
);
