export const AuthInitialStates = {
  isLoading: false,
  loggedIn: false,
  user: null,
  isUpdated: false,
  isAuthenticated: false,
  isLinkVerified: false,
  isForgetPasswordLoading: false,

  isSubmitting: false,
  isAdded: false,
  isCardLoading: false,
  cards: [],

  isInviting: false,
  isInvited: false,
  isInviteLoading: false,
  inviteUsers: [],

  guestData: {},
};

export const ResetPasswordInitialStates = {
  ispasswordLoading: false,
  verifyTokenLoading: true,
  isPasswordLinkVerified: false,
  message: "",
  data: "",
  passwordSet: false,
};
