export const AppRoutes = {
  HOME: "/",
  HOMEPAGE: "/home",
  PAGE_NOT_FOUND: "/404",
  PAGE_500: "/500",
  EXPLORE: "/explore",
  DASHBOARD: "/dashboard",
  LOGIN: "/login",
  VERIFY: "/verify/:id",
  REGISTER: "/register",
  RESET_PASSWORD: "/reset-password/:id",
  FORGOT_PASSWORD: "/forgot-password",
  PROFILE: "/profile",
  SETTINGS: "/settings",
  SAVEDSEARCH: "/saved-searches",
  OPPORTUNITY: "/opportunity",
  FOLLOWED_OPPORTUNITY: "/followed-opportunity",
  VIEWOPPORTUNITY: "/opportunity/:id",
  PIPELINE: "/pipeline",
  VIEWPIPELINE: "/pipeline/:id",
  AWARDS: "/awards",
  FUNDING_AWARDS: "/awards/funding-awards",
  VIEWAWARD: "/awards/:id",
  PSC: "/psc",
  VIEWPSCCATEGORY: "/psc/:id",
  NAICS: "/naics",
  VIEWNAICSCATEGORIE: "/naics/:id",
  FEDERALAGENCIES: "/federalAgencies",
  VIEWFEDERALAGENCIES: "/federalAgencies/:id",
  FEDERALPEOPLE: "/federalPeople",
  VIEWFEDERALPEOPLE: "/federalPeople/:id",
  VENDOR: "/vendor",
  VIEWVENDOR: "/vendor/:id",
  FOLLOWEDVENDORS: "/followedVendors",
  GUESTUSER: "/guestUser",
};
