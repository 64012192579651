/** @format */

import { handleActions } from "redux-actions";
import { StageActions } from "../actions";
import { StageInitialStates } from "../states";

export const StageReducer = handleActions(
  {
    [StageActions.UPDATE_STAGE_REQUEST]: (
      state = StageInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [StageActions.UPDATE_STAGE_SUCCESS]: (
      state = StageInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [StageActions.UPDATE_STAGE_FAILURE]: (
      state = StageInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),
  },
  StageInitialStates
);
