/** @format */

import { createAction } from "redux-actions";

export const GlobalSearchActions = {
  GLOBAL_SEARCH_REQUEST: "Request to update Global Search",
  GLOBAL_SEARCH_SUCCESS: "Global Search updated successfully",
  GLOBAL_SEARCH_FAILURE: "Failed to update Global Search",
};

// Update Global Search  actions
export const getGlobalSearchRequest = createAction(
  GlobalSearchActions.GLOBAL_SEARCH_REQUEST
);
export const globalSearchSuccess = createAction(
  GlobalSearchActions.GLOBAL_SEARCH_SUCCESS
);
export const globalSearchFailed = createAction(
  GlobalSearchActions.GLOBAL_SEARCH_FAILURE
);
