/** @format */

export const ModalInitialStates = {
  cardModalOpen: false,
  pipelineModalOpen: false,
  viewPipelineModalOpen: false,
  stageModalOpen: false,
  noteModalOpen: false,
  savedSearchModalOpen: false,
  inviteModalOpen: false,
  filterModal: false,
  fileModalOpen: false,
  hierarchyModalOpen: false,
  subscriptionModalOpen: false,
};

export const SidebarInitialStates = {
  sidebarShow: "responsive",
};
