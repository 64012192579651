/** @format */

import { createLogic } from "redux-logic";
import {
  addPipelineFailed,
  addPipelineSuccess,
  deletePipelineFailed,
  exportPipelineSuccess,
  PipelineActions,
  pipelineListFailed,
  pipelineListSuccess,
  redirectTo,
  updatePipelineFailed,
  updatePipelineSuccess,
  viewPipelineFailed,
  viewPipelineSuccess,
  updateOpportunityStageSuccess,
  updateOpportunityStageFailed,
  reorderStageFailed,
  reorderStageSuccess,
  deleteStageOpportunitySuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";
import { AppRoutes } from "config";
/**
 *
 */
const addPiplelineLogic = createLogic({
  type: PipelineActions.REQUEST_ADD_PIPELINE,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_PIPELINE.service,
      ApiRoutes.ADD_PIPELINE.url,
      ApiRoutes.ADD_PIPELINE.method,
      ApiRoutes.ADD_PIPELINE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addPipelineSuccess());

      dispatch(
        redirectTo({
          path: AppRoutes.VIEWPIPELINE.replace(":id", responseData.data._id),
        })
      );
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addPipelineFailed());
      done();
    }
  },
});

/**
 *
 */
const getPiplelinesLogic = createLogic({
  type: PipelineActions.REQUEST_PIPELINES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PIPELINES.service,
      ApiRoutes.GET_PIPELINES.url,
      ApiRoutes.GET_PIPELINES.method,
      ApiRoutes.GET_PIPELINES.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(pipelineListFailed());
      done();
    } else {
      dispatch(
        pipelineListSuccess({
          pipelines: responseData.data,
          isEmpty: responseData.isEmpty,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const updatePiplelineLogic = createLogic({
  type: PipelineActions.UPDATE_PIPELINE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_PIPELINE.service,
      ApiRoutes.UPDATE_PIPELINE.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_PIPELINE.method,
      ApiRoutes.UPDATE_PIPELINE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updatePipelineSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updatePipelineFailed());
      done();
    }
  },
});

/**
 *
 */
const viewPiplelineLogic = createLogic({
  type: PipelineActions.VIEW_PIPELINE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PIPELINE.service,
      ApiRoutes.VIEW_PIPELINE.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_PIPELINE.method,
      ApiRoutes.VIEW_PIPELINE.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      dispatch(viewPipelineFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewPipelineSuccess({
          pipelineData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const deletePiplelineLogic = createLogic({
  type: PipelineActions.DELETE_PIPELINE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_PIPELINE.service,
      ApiRoutes.DELETE_PIPELINE.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_PIPELINE.method,
      ApiRoutes.DELETE_PIPELINE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(deletePipelineFailed(messages[0]));
      done();
    } else {
      dispatch(redirectTo({ path: AppRoutes.HOME }));
      dispatch(messages[0]);
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const exportPiplelineLogic = createLogic({
  type: PipelineActions.EXPORT_CSV_PIPELINE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_PIPELINE.service,
      ApiRoutes.EXPORT_CSV_PIPELINE.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_CSV_PIPELINE.method,
      ApiRoutes.EXPORT_CSV_PIPELINE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      done();
    } else {
      dispatch(exportPipelineSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const updateOpportunityStageLogic = createLogic({
  type: PipelineActions.UPDATE_OPPORTUNITY_STAGE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_OPPORTUNITY_STAGE.service,
      ApiRoutes.UPDATE_OPPORTUNITY_STAGE.url,
      ApiRoutes.UPDATE_OPPORTUNITY_STAGE.method,
      ApiRoutes.UPDATE_OPPORTUNITY_STAGE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(updateOpportunityStageSuccess());
      showSuccess(messages[0]);
      let pipelineData = action.payload.pipelineData;
      pipelineData.updatedAt = new Date();
      dispatch(
        viewPipelineSuccess({
          pipelineData: pipelineData,
        })
      );
      done();
    } else {
      showError(messages[0]);
      dispatch(updateOpportunityStageFailed());
      done();
    }
  },
});

/**
 *
 */
const reorderOpportunityStageLogic = createLogic({
  type: PipelineActions.REORDER_OPPORTUNITY_STAGE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.REORDER_OPPORTUNITY_STAGE.service,
      ApiRoutes.REORDER_OPPORTUNITY_STAGE.url,
      ApiRoutes.REORDER_OPPORTUNITY_STAGE.method,
      ApiRoutes.REORDER_OPPORTUNITY_STAGE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(reorderStageSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(reorderStageFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteStageOpportunityLogic = createLogic({
  type: PipelineActions.DELETE_STAGE_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_STAGE_OPPORTUNITY.service,
      ApiRoutes.DELETE_STAGE_OPPORTUNITY.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_STAGE_OPPORTUNITY.method,
      ApiRoutes.DELETE_STAGE_OPPORTUNITY.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      done();
    } else {
      dispatch(deleteStageOpportunitySuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

export const PipelineLogics = [
  addPiplelineLogic,
  getPiplelinesLogic,
  updatePiplelineLogic,
  viewPiplelineLogic,
  deletePiplelineLogic,
  exportPiplelineLogic,
  updateOpportunityStageLogic,
  reorderOpportunityStageLogic,
  deleteStageOpportunityLogic,
];
