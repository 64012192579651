/** @format */

import { handleActions } from "redux-actions";
import { FederalAgenciesActions } from "../actions";
import { FederalAgenciesInitialStates } from "../states";

export const FederalAgenciesReducer = handleActions(
  {
    [FederalAgenciesActions.REQUEST_FEDERALAGENCIES_LIST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      federalagencies: [],
    }),
    [FederalAgenciesActions.FEDERALAGENCIES_LIST_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      federalagencies: action.payload.federalagencies,
      isEmpty: action.payload.isEmpty,
      totalRecords: action.payload.totalRecords,
    }),
    [FederalAgenciesActions.FEDERALAGENCIES_LIST_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [FederalAgenciesActions.VIEW_FEDERALAGENCIES_REQUEST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      federalagenciesData: {},
    }),
    [FederalAgenciesActions.VIEW_FEDERALAGENCIES_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      federalagenciesData: action.payload.federalagenciesData,
    }),
    [FederalAgenciesActions.VIEW_FEDERALAGENCIES_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_REQUEST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),


    [FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_REQUEST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
      viewFederalAgencies: [],
    }),
    [FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      viewFederalAgencies: action.payload.viewFederalAgencies,
      totalRecords: action.payload.totalRecords,
    }),
    [FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),


    [FederalAgenciesActions.REQUEST_FEDERAL_HIERARCHY]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isHierarchyLoading: true,
      federalHierarchy: {},
    }),
    [FederalAgenciesActions.FEDERAL_HIERARCHY_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isHierarchyLoading: false,
      federalHierarchy: action.payload.federalHierarchy,
    }),
    [FederalAgenciesActions.FEDERAL_HIERARCHY_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isHierarchyLoading: false,
      error: true,
    }),

    [FederalAgenciesActions.REQUEST_FILTER_PARENT_AGENCY_LIST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoadingParentAgency: true,
      parentAgencyList: [],
    }),
    [FederalAgenciesActions.FILTER_PARENT_AGENCY_LIST_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoadingParentAgency: false,
      parentAgencyList: action.payload.parentAgencyList,
    }),
    [FederalAgenciesActions.FILTER_PARENT_AGENCY_LIST_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isLoadingParentAgency: false,
      error: true,
    }),

    [FederalAgenciesActions.REQUEST_AGENCY_FUNDING_ANALYSIS_LIST]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: true,
      fundingData: [],
      fundingGraphData: [],
    }),
    [FederalAgenciesActions.AGENCY_FUNDING_ANALYSIS_LIST_SUCCESS]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      fundingData: action.payload.fundingData,
      fundingGraphData: action.payload.fundingGraphData,
      totalDollarsObligated: action.payload.totalDollarsObligated,
    }),
    [FederalAgenciesActions.AGENCY_FUNDING_ANALYSIS_LIST_FAILURE]: (
      state = FederalAgenciesInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      error: true,
    }),
  },
  FederalAgenciesInitialStates
);
