/** @format */

import { createLogic } from "redux-logic";
import {
  StageActions,
  updateStageSuccess,
  updateStageFailed,
  viewPipelineRequest,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const updateStageLogic = createLogic({
  type: StageActions.UPDATE_STAGE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_STAGE.service,
      ApiRoutes.UPDATE_STAGE.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_STAGE.method,
      ApiRoutes.UPDATE_STAGE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateStageSuccess());
      dispatch(viewPipelineRequest({ id: action.payload.pipelineId }));
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateStageFailed());
      done();
    }
  },
});

export const StageLogics = [updateStageLogic];
