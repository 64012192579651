/** @format */

import { createAction } from "redux-actions";

export const AuthActions = {
  REQUEST_LOGIN: "Request user login!",
  LOGIN_FAILED: "Login failed",
  LOGOUT_REQUEST: "Request logout",

  GET_USER_DETAILS: "Request to fetch user details",
  USER_DETAILS_SUCCESS: "User details fetched successfully!",
  USER_DETAILS_FAILED: "Failed to fetch User details!",

  USER_SIGNUP_REQUEST: "Request user Signup!",
  USER_SIGNUP_SUCCESS: "User Signup success!",
  USER_SIGNUP_FAILURE: "User Signup failed",

  LINK_VERIFICATION_REQUEST: "Request link verification",
  LINK_VERIFICATION_SUCCESS: "Link verification success",
  LINK_VERIFICATION_FAILED: "Link verification failed",

  REQUEST_FORGOT_PASSWORD: "Request forgot password",
  FORGOT_PASSWORD_SUCCESS: "Forgot password success",
  FORGOT_PASSWORD_FAILURE: "Forgot password failed",

  LINK_VERIFICATION_PASSWORD_REQUEST: "Request for Password link verification",
  LINK_VERIFICATION_PASSWORD_SUCCESS: "Password link verified successfully",
  LINK_VERIFICATION_PASSWORD_FAILURE: "Failed to verify Password link",

  SET_PASSWORD_REQUEST: "Request for set password",
  SET_PASSWORD_SUCCESS: "Password set successfully",
  SET_PASSWORD_FAILURE: "Failed to set password",

  CHANGE_PASSWORD_REQUEST: "Request for password change",
  CHANGE_PASSWORD_SUCCESS: "Password updated successfully",
  CHANGE_PASSWORD_FAILED: "Failed to update password",

  REQUEST_UPDATE_PROFILE: "Request for update profile details",
  UPDATE_PROFILE_SUCCESS: "Profile updated successfully!",
  UPDATE_PROFILE_FAILED: "Failed to update profile details",

  UPDATE_NOTIFICATION_REQUEST: "Request to update notification",
  UPDATE_NOTIFICATION_SUCCESS: "Notification updated successfully",
  UPDATE_NOTIFICATION_FAILED: "Failed to update notification",

  REQUEST_ADD_CARD: "Request to add card details",
  ADD_CARD_SUCCESS: "Card details added successfully",
  ADD_CARD_FAILED: "Failed to add card details",

  REQUEST_CARD_LIST: "Request to fetch card list details",
  CARD_LIST_SUCCESS: "Card list details fetched successfully",
  CARD_LIST_FAILED: "Failed to fetched card list details",

  DELETE_CARD_REQUEST: "Request to delete card details",
  DELETE_CARD_FAILED: "Failed to delete card detail",

  UPDATE_CARD_STATUS_REQUEST: "Request to update card status",
  UPDATE_CARD_STATUS_FAILED: "Failed to update  card status",

  REQUEST_INVITE_USER: "Request to invite user",
  INVITE_USER_SUCCESS: "User invite successfully",
  INVITE_USER_FAILED: "Failed to invite user",

  REQUEST_INVITE_USER_LIST: "Request to fetch invite user list",
  INVITE_USER_LIST_SUCCESS: "Invite user list fetched successfully",
  INVITE_USER_LIST_FAILED: "Failed to fetched invite user list",

  REMOVE_USER_REQUEST: "Request to remove user",
  REMOVE_USER_FAILED: "Failed to remove user",

  REQUEST_SUBSCRIPTION_LIST: "Request to fetch subscription list details",
  SUBSCRIPTION_LIST_SUCCESS: "Subscription list details fetched successfully",
  SUBSCRIPTION_LIST_FAILED: "Failed to fetched subscription list details",

  CANCEL_SUBSCRIPTION_REQUEST: "Request to cancel subscription details",
  CANCEL_SUBSCRIPTION_SUCCESS: "subscription cancel successfully",
  CANCEL_SUBSCRIPTION_FAILED: "Failed to cancel subscription detail",
  RE_INVITE_USER_REQUEST: "Request to re invite user",

  GET_GUEST_DATA: "Request to fetch Guest Data",
  GUEST_DATA_SUCCESS: "Guest Data fetched successfully!",
  GUEST_DATA_FAILED: "Failed to Guest Data!",

};

export const getGuestData = createAction(AuthActions.GET_GUEST_DATA);
export const guestDataSuccess = createAction(AuthActions.GUEST_DATA_SUCCESS);
export const guestDataFailed = createAction(AuthActions.GUEST_DATA_FAILED);

export const requestLogin = createAction(AuthActions.REQUEST_LOGIN);
// export const loginSuccess = createAction(AuthActions.LOGIN_SUCCESS);
export const loginFailed = createAction(AuthActions.LOGIN_FAILED);
export const requestLogout = createAction(AuthActions.LOGOUT_REQUEST);

export const userSignupRequest = createAction(AuthActions.USER_SIGNUP_REQUEST);
export const userSignupSuccess = createAction(AuthActions.USER_SIGNUP_SUCCESS);
export const userSignupFailed = createAction(AuthActions.USER_SIGNUP_SUCCESS);

export const requestLinkVerification = createAction(
  AuthActions.LINK_VERIFICATION_REQUEST
);
export const linkVerificationSuccess = createAction(
  AuthActions.LINK_VERIFICATION_SUCCESS
);
export const linkVerificationFailed = createAction(
  AuthActions.LINK_VERIFICATION_FAILED
);

export const requestForgotPassword = createAction(
  AuthActions.REQUEST_FORGOT_PASSWORD
);
export const forgotPasswordSuccess = createAction(
  AuthActions.FORGOT_PASSWORD_SUCCESS
);
export const forgotPasswordFailed = createAction(
  AuthActions.FORGOT_PASSWORD_FAILURE
);

export const requestPasswordLinkVerification = createAction(
  AuthActions.LINK_VERIFICATION_PASSWORD_REQUEST
);
export const passwordLinkVerificationSuccess = createAction(
  AuthActions.LINK_VERIFICATION_PASSWORD_SUCCESS
);
export const passwordLinkVerificationFailed = createAction(
  AuthActions.LINK_VERIFICATION_PASSWORD_FAILURE
);
export const requestSetPassword = createAction(
  AuthActions.SET_PASSWORD_REQUEST
);
export const setPasswordSuccess = createAction(
  AuthActions.SET_PASSWORD_SUCCESS
);
export const setPasswordFailed = createAction(AuthActions.SET_PASSWORD_FAILURE);

export const getUserDetails = createAction(AuthActions.GET_USER_DETAILS);
export const userDetailsSuccess = createAction(
  AuthActions.USER_DETAILS_SUCCESS
);
export const userDetailsFailed = createAction(AuthActions.USER_DETAILS_FAILED);

export const changePasswordReq = createAction(
  AuthActions.CHANGE_PASSWORD_REQUEST
);
export const changePasswordSuccess = createAction(
  AuthActions.CHANGE_PASSWORD_SUCCESS
);
export const changePasswordFailed = createAction(
  AuthActions.CHANGE_PASSWORD_FAILED
);

export const requestUpdateProfile = createAction(
  AuthActions.REQUEST_UPDATE_PROFILE
);
export const updateProfileSuccess = createAction(
  AuthActions.UPDATE_PROFILE_SUCCESS
);
export const updateProfileFailure = createAction(
  AuthActions.UPDATE_PROFILE_FAILED
);

// Update notification
export const updateNotificationRequest = createAction(
  AuthActions.UPDATE_NOTIFICATION_REQUEST
);
export const updateNotificationSuccess = createAction(
  AuthActions.UPDATE_NOTIFICATION_SUCCESS
);
export const updateNotificationFailed = createAction(
  AuthActions.UPDATE_NOTIFICATION_FAILED
);

// Add card actions
export const requestAddCard = createAction(AuthActions.REQUEST_ADD_CARD);
export const addCardSuccess = createAction(AuthActions.ADD_CARD_SUCCESS);
export const addCardFailed = createAction(AuthActions.ADD_CARD_FAILED);

// List card actions
export const requestCardList = createAction(AuthActions.REQUEST_CARD_LIST);
export const cardListSuccess = createAction(AuthActions.CARD_LIST_SUCCESS);
export const cardListFailed = createAction(AuthActions.CARD_LIST_FAILED);

// Delete card actions
export const deleteCardRequest = createAction(AuthActions.DELETE_CARD_REQUEST);
export const deleteCardFailed = createAction(AuthActions.DELETE_CARD_FAILED);

// Update Card Status
export const updateCardStatusRequest = createAction(
  AuthActions.UPDATE_CARD_STATUS_REQUEST
);

export const updateCardStatusFailed = createAction(
  AuthActions.UPDATE_CARD_STATUS_FAILED
);

// Add Invite user actions
export const requestInviteUser = createAction(AuthActions.REQUEST_INVITE_USER);
export const inviteUserSuccess = createAction(AuthActions.INVITE_USER_SUCCESS);
export const inviteUserFailed = createAction(AuthActions.INVITE_USER_FAILED);

// List Invite user actions
export const requestInviteUserList = createAction(
  AuthActions.REQUEST_INVITE_USER_LIST
);
export const inviteUserListSuccess = createAction(
  AuthActions.INVITE_USER_LIST_SUCCESS
);
export const inviteUserListFailed = createAction(
  AuthActions.INVITE_USER_LIST_FAILED
);

// Delete Invite user actions
export const removeUserRequest = createAction(AuthActions.REMOVE_USER_REQUEST);
export const removeUserFailed = createAction(AuthActions.REMOVE_USER_FAILED);

// List subscription actions
export const requestSubscriptionList = createAction(
  AuthActions.REQUEST_SUBSCRIPTION_LIST
);
export const subscriptionListSuccess = createAction(
  AuthActions.SUBSCRIPTION_LIST_SUCCESS
);
export const subscriptionListFailed = createAction(
  AuthActions.SUBSCRIPTION_LIST_FAILED
);

// Cancel subscription actions
export const cancelSubscriptionRequest = createAction(
  AuthActions.CANCEL_SUBSCRIPTION_REQUEST
);

export const cancelSubscriptionSuccess = createAction(
  AuthActions.CANCEL_SUBSCRIPTION_SUCCESS
);
export const cancelSubscriptionFailed = createAction(
  AuthActions.CANCEL_SUBSCRIPTION_FAILED
);
export const reInviteUserRequest = createAction(
  AuthActions.RE_INVITE_USER_REQUEST
);
