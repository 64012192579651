import { createAction } from "redux-actions";

export const VendorActions = {
  REQUEST_VENDOR_LIST: "Request to fetch Vendor list",
  VENDOR_LIST_SUCCESS: "Vendor list fetched successfully",
  VENDOR_LIST_FAILURE: "Failed to fetched Vendor list",

  REQUEST_LIKED_VENDOR_LIST: "Request to fetch liked vendor list",
  LIKED_VENDOR_LIST_SUCCESS: "like vendor list fetched successfully",
  LIKED_VENDOR_LIST_FAILURE: "Failed to fetched liked vendor list",

  VIEW_VENDOR_REQUEST: "Request to view Vendor",
  VIEW_VENDOR_SUCCESS: "Vendor details fetched successfully",
  VIEW_VENDOR_FAILURE: "Failed to fetch vendor details",

  LIKE_VENDOR_REQUEST: "Request to like vendor",
  UNLIKE_VENDOR_REQUEST: "Request to unlike vendor",

  EXPORT_CSV_VENDOR_REQUEST: "Request to export csv vendor",
  EXPORT_CSV_VENDOR_SUCCESS: "vendor export successfully",
  EXPORT_CSV_VENDOR_FAILURE: "vendor export Failed",

  EXPORT_CSV_FOLLOWED_VENDOR_REQUEST: "Request to export csv followed vendor",
  EXPORT_CSV_FOLLOWED_VENDOR_SUCCESS: "followed vendor export successfully",
  EXPORT_CSV_FOLLOWED_VENDOR_FAILURE: "followed vendor export Failed",


  EXPORT_VIEW_VENDOR_REQUEST: "Request to export view vendor",
  EXPORT_VIEW_VENDOR_SUCCESS: "vendor export view successfully",
  EXPORT_VIEW_VENDOR_FAILURE: "vendor export view Failed",

  REQUEST_VENDOR_FUNDING_ANALYSIS_LIST:
    "Request to fetch Vendor funding Analysis list",
  VENDOR_FUNDING_ANALYSIS_LIST_SUCCESS:
    "Vendor funding Analysis list fetched successfully",
  VENDOR_FUNDING_ANALYSIS_LIST_FAILURE:
    "Failed to fetched Vendor funding Analysis list",
  VENDOR_LIKE_UNLIKE_SUCCESS: "Vendor like unlike successfully",
};

//follow like actions
export const requestLikeVendor = createAction(
  VendorActions.LIKE_VENDOR_REQUEST
);

//unfollow like actions
export const requestUnlikeVendor = createAction(
  VendorActions.UNLIKE_VENDOR_REQUEST
);

export const requestLikedVendorList = createAction(
  VendorActions.REQUEST_LIKED_VENDOR_LIST
);
export const LikedVendorListSuccess = createAction(
  VendorActions.LIKED_VENDOR_LIST_SUCCESS
);
export const LikedVendorListFailed = createAction(
  VendorActions.LIKED_VENDOR_LIST_FAILURE
);

// List vendor actions
export const requestVendorList = createAction(
  VendorActions.REQUEST_VENDOR_LIST
);
export const vendorListSuccess = createAction(
  VendorActions.VENDOR_LIST_SUCCESS
);
export const vendorLikeUnlikeSuccess = createAction(
  VendorActions.VENDOR_LIKE_UNLIKE_SUCCESS
);
export const vendorListFailed = createAction(VendorActions.VENDOR_LIST_FAILURE);

// View vendor actions
export const viewVendorRequest = createAction(
  VendorActions.VIEW_VENDOR_REQUEST
);
export const viewVendorSuccess = createAction(
  VendorActions.VIEW_VENDOR_SUCCESS
);
export const viewVendorFailed = createAction(VendorActions.VIEW_VENDOR_FAILURE);

export const exportVendorRequest = createAction(
  VendorActions.EXPORT_CSV_VENDOR_REQUEST
);
export const exportVendorSuccess = createAction(
  VendorActions.EXPORT_CSV_VENDOR_SUCCESS
);
export const exportVendorFailed = createAction(
  VendorActions.EXPORT_CSV_VENDOR_FAILURE
);

export const exportViewVendorRequest = createAction(
  VendorActions.EXPORT_VIEW_VENDOR_REQUEST
);
export const exportViewVendorSuccess = createAction(
  VendorActions.EXPORT_VIEW_VENDOR_SUCCESS
);
export const exportViewVendorFailed = createAction(
  VendorActions.EXPORT_VIEW_VENDOR_FAILURE
);



export const exportFollowedVendorRequest = createAction(
  VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_REQUEST
);
export const exportFollowedVendorSuccess = createAction(
  VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_SUCCESS
);
export const exportFollowedVendorFailed = createAction(
  VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_FAILURE
);





// List Vendor funding analysis actions
export const requestVendorFundingAnalysisList = createAction(
  VendorActions.REQUEST_VENDOR_FUNDING_ANALYSIS_LIST
);
export const vendorFundingAnalysisListSuccess = createAction(
  VendorActions.VENDOR_FUNDING_ANALYSIS_LIST_SUCCESS
);
export const vendorFundingAnalysisListFailed = createAction(
  VendorActions.VENDOR_FUNDING_ANALYSIS_LIST_FAILURE
);
