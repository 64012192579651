/** @format */

import { handleActions } from "redux-actions";
import { PscActions } from "../actions";
import { PscInitialStates } from "../states";

export const PscReducer = handleActions(
  {
    [PscActions.REQUEST_PSC_LIST]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: true,
      psc: [],
    }),
    [PscActions.PSC_LIST_SUCCESS]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: false,
      psc: action.payload.psc,
      totalRecords: action.payload.totalRecords,
    }),
    [PscActions.PSC_LIST_FAILURE]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [PscActions.VIEW_PSC_REQUEST]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: true,
      pscData: {},
    }),
    [PscActions.VIEW_PSC_SUCCESS]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: false,
      pscData: action.payload.pscData,
    }),
    [PscActions.VIEW_PSC_FAILURE]: (state = PscInitialStates, action) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [PscActions.EXPORT_CSV_PSC_REQUEST]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [PscActions.EXPORT_CSV_PSC_SUCCESS]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [PscActions.EXPORT_CSV_PSC_FAILURE]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [PscActions.REQUEST_PSC_HIERARCHY_LIST]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isPscHierarchyLoading: true,
      pscHierarchy: {},
    }),
    [PscActions.PSC_HIERARCHY_LIST_SUCCESS]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isPscHierarchyLoading: false,
      pscHierarchy: action.payload.pscHierarchy,
    }),
    [PscActions.PSC_HIERARCHY_LIST_FAILURE]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isPscHierarchyLoading: false,
      error: true,
    }),

    [PscActions.REQUEST_PSC_FUNDING_ANALYSIS_LIST]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: true,
      fundingData: [],
      fundingGraphData: [],
    }),
    [PscActions.PSC_FUNDING_ANALYSIS_LIST_SUCCESS]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      fundingData: action.payload.fundingData,
      fundingGraphData: action.payload.fundingGraphData,
      totalDollarsObligated: action.payload.totalDollarsObligated,
    }),
    [PscActions.PSC_FUNDING_ANALYSIS_LIST_FAILURE]: (
      state = PscInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      error: true,
    }),
  },
  PscInitialStates
);
