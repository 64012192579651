/** @format */

import { createAction } from "redux-actions";

export const OpportunityActions = {
  REQUEST_OPPORTUNITY_LIST: "Request to fetch opportunity list",
  OPPORTUNITY_LIST_SUCCESS: "Opportunity list fetched successfully",
  OPPORTUNITY_LIST_FAILURE: "Failed to fetched opportunity list",

  REQUEST_FOLLOWED_OPPORTUNITY_LIST:
    "Request to fetch followed opportunity list",
  FOLLOWED_OPPORTUNITY_LIST_SUCCESS:
    "Followed Opportunity list fetched successfully",
  FOLLOWED_OPPORTUNITY_LIST_FAILURE:
    "Failed to fetched followed opportunity list",

  VIEW_OPPORTUNITY_REQUEST: "Request to view opportunity",
  VIEW_OPPORTUNITY_SUCCESS: "Opportunity details fetched successfully",
  VIEW_OPPORTUNITY_FAILURE: "Failed to fetch opportunity details",

  REQUEST_ADD_TO_PIPELINE: "Request to add to pipeline",
  ADD_TO_PIPELINE_SUCCESS: "Opportunity added to pipeline successfully",
  ADD_TO_PIPELINE_FAILURE: "Failed to Opportunity added to pipeline",

  FOLLOW_OPPORTUNITY_REQUEST: "Request to follow opportunity",
  UNFOLLOW_OPPORTUNITY_REQUEST: "Request to unfollow opportunity",

  REQUEST_ADD_NOTE: "Request to add note",
  ADD_NOTE_SUCCESS: "Note added successfully",
  ADD_NOTE_FAILURE: "Failed to add note",

  REQUEST_NOTE_LIST: "Request to fetch notes",
  NOTE_LIST_SUCCESS: "Note fetched successfully",
  NOTE_LIST_FAILURE: "Failed to fetched notes",

  UPDATE_NOTE_REQUEST: "Request to update note",
  UPDATE_NOTE_SUCCESS: "Note updated successfully",
  UPDATE_NOTE_FAILURE: "Failed to update note",

  DELETE_NOTE_REQUEST: "Request to delete note",
  DELETE_NOTE_FAILURE: "Failed to delete note",

  EXPORT_CSV_OPPORTUNITY_REQUEST: "Request to export csv opportunity",
  EXPORT_CSV_OPPORTUNITY_SUCCESS: "Opportunity export successfully",
  EXPORT_CSV_OPPORTUNITY_FAILURE: "Opportunity export Failed",

  EXPORT_VIEW_OPPORTUNITY_REQUEST: "Request to export view opportunity",
  EXPORT_VIEW_OPPORTUNITY_SUCCESS: "Opportunity export view successfully",
  EXPORT_VIEW_OPPORTUNITY_FAILURE: "Opportunity export view Failed",

  EXPORT_CSV_FOLLOWED_OPPORTUNITY_REQUEST:
    "Request to export csv followed opportunity",
  EXPORT_CSV_FOLLOWED_OPPORTUNITY_SUCCESS:
    "followed opportunity export successfully",
  EXPORT_CSV_FOLLOWED_OPPORTUNITY_FAILURE: "followed opportunity export Failed",

  REQUEST_FILTER_AGENCY_LIST: "Request to fetch filter agency list",
  FILTER_AGENCY_LIST_SUCCESS: "Filter agency list fetched successfully",
  FILTER_AGENCY_LIST_FAILURE: "Failed to fetched filter agency list",

  REQUEST_FILTER_PSC_LIST: "Request to fetch filter PSC list",
  FILTER_PSC_LIST_SUCCESS: "Filter PSC list fetched successfully",
  FILTER_PSC_LIST_FAILURE: "Failed to fetched filter PSC list",

  REQUEST_FILTER_NAICS_LIST: "Request to fetch filter NAICS list",
  FILTER_NAICS_LIST_SUCCESS: "Filter NAICS list fetched successfully",
  FILTER_NAICS_LIST_FAILURE: "Failed to fetched filter NAICS list",

  REQUEST_FILTER_PERSON_LIST: "Request to fetch filter person list",
  FILTER_PERSON_LIST_SUCCESS: "Filter person list fetched successfully",
  FILTER_PERSON_LIST_FAILURE: "Failed to fetched filter person list",

  REQUEST_FILTER_VENDOR_LIST: "Request to fetch filter vendor list",
  FILTER_VENDOR_LIST_SUCCESS: "Filter vendor list fetched successfully",
  FILTER_VENDOR_LIST_FAILURE: "Failed to fetched filter vendor list",

  REQUEST_OPPORTUNITY_STACK_LIST: "Request to fetch opportunity stack list",
  OPPORTUNITY_STACK_LIST_SUCCESS: "Opportunity stack list fetched successfully",
  OPPORTUNITY_STACK_LIST_FAILURE: "Failed to fetched opportunity stack list",
  OPPORTUNITY_LIKE_UNLIKE_SUCCESS: "Opportunity like unlike successfully",
  RESET_OPPORTUNITY_VALUE: "Request to reset opportunity value",
};

// List opportunity actions
export const requestOpportunityList = createAction(
  OpportunityActions.REQUEST_OPPORTUNITY_LIST
);
export const opportunityListSuccess = createAction(
  OpportunityActions.OPPORTUNITY_LIST_SUCCESS
);
export const opportunityLikeUnlikeSuccess = createAction(
  OpportunityActions.OPPORTUNITY_LIKE_UNLIKE_SUCCESS
);
export const opportunityListFailed = createAction(
  OpportunityActions.OPPORTUNITY_LIST_FAILURE
);

export const requestFollowedOpportunityList = createAction(
  OpportunityActions.REQUEST_FOLLOWED_OPPORTUNITY_LIST
);
export const followedOpportunityListSuccess = createAction(
  OpportunityActions.FOLLOWED_OPPORTUNITY_LIST_SUCCESS
);
export const followedOpportunityListFailed = createAction(
  OpportunityActions.FOLLOWED_OPPORTUNITY_LIST_FAILURE
);

// View opportunity actions
export const viewOpportunityRequest = createAction(
  OpportunityActions.VIEW_OPPORTUNITY_REQUEST
);
export const viewOpportunitySuccess = createAction(
  OpportunityActions.VIEW_OPPORTUNITY_SUCCESS
);
export const viewOpportunityFailed = createAction(
  OpportunityActions.VIEW_OPPORTUNITY_FAILURE
);

//add opportunity to pipeline actions
export const requestAddToPipeline = createAction(
  OpportunityActions.REQUEST_ADD_TO_PIPELINE
);
export const addToPipelineSuccess = createAction(
  OpportunityActions.ADD_TO_PIPELINE_SUCCESS
);
export const addToPipelineFailed = createAction(
  OpportunityActions.ADD_TO_PIPELINE_FAILURE
);

//follow opportunity actions
export const requestFollowOpportunity = createAction(
  OpportunityActions.FOLLOW_OPPORTUNITY_REQUEST
);

//unfollow opportunity actions
export const requestUnfollowOpportunity = createAction(
  OpportunityActions.UNFOLLOW_OPPORTUNITY_REQUEST
);

// Add note actions
export const requestAddNote = createAction(OpportunityActions.REQUEST_ADD_NOTE);
export const addNoteSuccess = createAction(OpportunityActions.ADD_NOTE_SUCCESS);
export const addNoteFailed = createAction(OpportunityActions.ADD_NOTE_FAILURE);

// List note actions
export const requestNoteList = createAction(
  OpportunityActions.REQUEST_NOTE_LIST
);
export const noteListSuccess = createAction(
  OpportunityActions.NOTE_LIST_SUCCESS
);
export const noteListFailed = createAction(
  OpportunityActions.NOTE_LIST_FAILURE
);

// Update note actions
export const updateNoteRequest = createAction(
  OpportunityActions.UPDATE_NOTE_REQUEST
);
export const updateNoteSuccess = createAction(
  OpportunityActions.UPDATE_NOTE_SUCCESS
);
export const updateNoteFailed = createAction(
  OpportunityActions.UPDATE_NOTE_FAILURE
);

// Delete note actions
export const deleteNoteRequest = createAction(
  OpportunityActions.DELETE_NOTE_REQUEST
);
export const deleteNoteFailed = createAction(
  OpportunityActions.DELETE_NOTE_FAILURE
);

export const exportOpportunityRequest = createAction(
  OpportunityActions.EXPORT_CSV_OPPORTUNITY_REQUEST
);
export const exportOpportunitySuccess = createAction(
  OpportunityActions.EXPORT_CSV_OPPORTUNITY_SUCCESS
);
export const exportOpportunityFailed = createAction(
  OpportunityActions.EXPORT_CSV_OPPORTUNITY_FAILURE
);

export const exportViewOpportunityRequest = createAction(
  OpportunityActions.EXPORT_VIEW_OPPORTUNITY_REQUEST
);
export const exportViewOpportunitySuccess = createAction(
  OpportunityActions.EXPORT_VIEW_OPPORTUNITY_SUCCESS
);
export const exportViewOpportunityFailed = createAction(
  OpportunityActions.EXPORT_VIEW_OPPORTUNITY_FAILURE
);

export const exportFollowedOpportunityRequest = createAction(
  OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_REQUEST
);
export const exportFollowedOpportunitySuccess = createAction(
  OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_SUCCESS
);
export const exportFollowedOpportunityFailed = createAction(
  OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_FAILURE
);

// Agency List actions
export const requestFilterAgencyList = createAction(
  OpportunityActions.REQUEST_FILTER_AGENCY_LIST
);
export const filterAgencyListSuccess = createAction(
  OpportunityActions.FILTER_AGENCY_LIST_SUCCESS
);
export const filterAgencyListFailed = createAction(
  OpportunityActions.FILTER_AGENCY_LIST_FAILURE
);

// PSC List actions
export const requestFilterPscList = createAction(
  OpportunityActions.REQUEST_FILTER_PSC_LIST
);
export const filterPscListSuccess = createAction(
  OpportunityActions.FILTER_PSC_LIST_SUCCESS
);
export const filterPscListFailed = createAction(
  OpportunityActions.FILTER_PSC_LIST_FAILURE
);

// NAICS List actions
export const requestFilterNaicsList = createAction(
  OpportunityActions.REQUEST_FILTER_NAICS_LIST
);
export const filterNaicsListSuccess = createAction(
  OpportunityActions.FILTER_NAICS_LIST_SUCCESS
);
export const filterNaicsListFailed = createAction(
  OpportunityActions.FILTER_NAICS_LIST_FAILURE
);

// Person List actions
export const requestFilterPersonList = createAction(
  OpportunityActions.REQUEST_FILTER_PERSON_LIST
);
export const filterPersonListSuccess = createAction(
  OpportunityActions.FILTER_PERSON_LIST_SUCCESS
);
export const filterPersonListFailed = createAction(
  OpportunityActions.FILTER_PERSON_LIST_FAILURE
);

// Vendor List actions
export const requestFilterVendorList = createAction(
  OpportunityActions.REQUEST_FILTER_VENDOR_LIST
);
export const filterVendorListSuccess = createAction(
  OpportunityActions.FILTER_VENDOR_LIST_SUCCESS
);
export const filterVendorListFailed = createAction(
  OpportunityActions.FILTER_VENDOR_LIST_FAILURE
);

export const requestOpportunityStackList = createAction(
  OpportunityActions.REQUEST_OPPORTUNITY_STACK_LIST
);
export const opportunityStackListSuccess = createAction(
  OpportunityActions.OPPORTUNITY_STACK_LIST_SUCCESS
);
export const opportunityStackListFailed = createAction(
  OpportunityActions.OPPORTUNITY_STACK_LIST_FAILURE
);
export const resetOpportunityValue = createAction(
  OpportunityActions.RESET_OPPORTUNITY_VALUE
);
