/** @format */

export const PipelineInitialStates = {
  isLoading: false,
  isSubmitting: false,
  isAdded: false,
  isUpdated: false,
  error: false,
  pipelines: [],
  pipelineData: {},
  isDownloaded: false,
};
