/** @format */

import { handleActions } from "redux-actions";
import { NotificationActions } from "../actions";
import { NotificationInitialStates } from "../states";

export const NotificationReducer = handleActions(
  {
    [NotificationActions.REQUEST_NOTIFICATION_LIST]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [NotificationActions.NOTIFICATION_LIST_SUCCESS]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      notifications: action.payload.notifications,
      totalRecords: action.payload.totalRecords,
      unReadRecords: action.payload.unReadRecords,
    }),
    [NotificationActions.NOTIFICATION_LIST_FAILURE]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [NotificationActions.UPDATE_NOTIFICATION_STATUS_SUCCESS]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
    }),
    [NotificationActions.UPDATE_NOTIFICATION_STATUS_FAILURE]: (
      state = NotificationInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  NotificationInitialStates
);
