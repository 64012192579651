/** @format */

import { createLogic } from "redux-logic";
import {
  exportNaicsSuccess,
  exportNaicsFailed,
  exportViewNaicsSuccess,
  exportViewNaicsFailed,
  NaicsActions,
  naicsListFailed,
  naicsListSuccess,
  viewNaicsFailed,
  viewNaicsSuccess,
  naicsHierarchyListSuccess,
  naicsHierarchyListFailed,
  parentNaicsListFailed,
  parentNaicsListSuccess,
  naicsFundingAnalysisListSuccess,
  naicsFundingAnalysisListFailed,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getNaicsLogic = createLogic({
  type: NaicsActions.REQUEST_NAICS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NAICS.service,
      ApiRoutes.GET_NAICS.url,
      ApiRoutes.GET_NAICS.method,
      ApiRoutes.GET_NAICS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(naicsListFailed());
      done();
    } else {
      dispatch(
        naicsListSuccess({
          naics: responseData.data,
          isEmpty: responseData.isEmpty,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

const viewNaicsLogic = createLogic({
  type: NaicsActions.VIEW_NAICS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_NAICS.service,
      ApiRoutes.VIEW_NAICS.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_NAICS.method,
      ApiRoutes.VIEW_NAICS.authenticate,
      undefined,
      // { federalPeopleSearch, searchGovermentFiles, contractAwardsSearch },
      undefined
    );

    if (isError) {
      dispatch(viewNaicsFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewNaicsSuccess({
          naicsData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const exportNaicsLogic = createLogic({
  type: NaicsActions.EXPORT_CSV_NAICS_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_NAICS.service,
      ApiRoutes.EXPORT_CSV_NAICS.url,
      ApiRoutes.EXPORT_CSV_NAICS.method,
      ApiRoutes.EXPORT_CSV_NAICS.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportNaicsFailed(messages[0]));
      done();
    } else {
      dispatch(exportNaicsSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */

const exportViewNaicsLogic = createLogic({
  type: NaicsActions.EXPORT_VIEW_NAICS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_NAICS.service,
      ApiRoutes.EXPORT_VIEW_NAICS.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_VIEW_NAICS.method,
      ApiRoutes.EXPORT_VIEW_NAICS.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewNaicsFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewNaicsSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const getNaicsHierarchyLogic = createLogic({
  type: NaicsActions.REQUEST_NAICS_HIERARCHY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NAICS_HIERARCHY.service,
      ApiRoutes.GET_NAICS_HIERARCHY.url,
      ApiRoutes.GET_NAICS_HIERARCHY.method,
      ApiRoutes.GET_NAICS_HIERARCHY.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(naicsHierarchyListFailed());
      done();
    } else {
      dispatch(
        naicsHierarchyListSuccess({
          naicsHierarchy: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterParentNaicsLogic = createLogic({
  type: NaicsActions.REQUEST_FILTER_PARENT_NAICS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_PARENT_NAICS_LIST.service,
        ApiRoutes.GET_PARENT_NAICS_LIST.url,
        ApiRoutes.GET_PARENT_NAICS_LIST.method,
        ApiRoutes.GET_PARENT_NAICS_LIST.authenticate,
        action.payload,
        undefined
      );
    if (isError) {
      dispatch(parentNaicsListFailed());
      done();
    } else {
      dispatch(
        parentNaicsListSuccess({
          parentNaicsList: responseData.data,
        })
      );
      done();
    }
  },
});

const getNaicsFundingAnalysisLogic = createLogic({
  type: NaicsActions.REQUEST_NAICS_FUNDING_ANALYSIS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NAICS_FUNDING_ANALYSIS.service,
      ApiRoutes.GET_NAICS_FUNDING_ANALYSIS.url,
      ApiRoutes.GET_NAICS_FUNDING_ANALYSIS.method,
      ApiRoutes.GET_NAICS_FUNDING_ANALYSIS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(naicsFundingAnalysisListFailed());
      done();
    } else {
      dispatch(
        naicsFundingAnalysisListSuccess({
          fundingData: responseData.data,
          fundingGraphData: responseData.graphData,
          totalDollarsObligated: responseData.totalDollarsObligated,
        })
      );
      done();
    }
  },
});

export const NaicsLogics = [
  getNaicsLogic,
  viewNaicsLogic,
  exportNaicsLogic,
  exportViewNaicsLogic,
  getNaicsHierarchyLogic,
  getFilterParentNaicsLogic,
  getNaicsFundingAnalysisLogic,
];
