/** @format */

import { createLogic } from "redux-logic";
import {
  exportAwardsSuccess,
  exportAwardsFailed,
  exportViewAwardsSuccess,
  exportViewAwardsFailed,
  AwardsActions,
  awardsListFailed,
  awardsListSuccess,
  funding_statusListFailed,
  funding_statusListSuccess,
  viewAwardsFailed,
  viewAwardsSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 *
 *
 */
const getAwardsLogic = createLogic({
  type: AwardsActions.REQUEST_AWARDS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_AWARDS.service,
      ApiRoutes.GET_AWARDS.url,
      ApiRoutes.GET_AWARDS.method,
      ApiRoutes.GET_AWARDS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(awardsListFailed());
      done();
    } else {
      dispatch(
        awardsListSuccess({
          awards: responseData.data,
          awardSetAside: responseData.awardSetAside,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

const getFundingAwardsLogic = createLogic({
  type: AwardsActions.REQUEST_FUNDING_AWARDS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FUNDING_AWARDS.service,
      ApiRoutes.GET_FUNDING_AWARDS.url,
      ApiRoutes.GET_FUNDING_AWARDS.method,
      ApiRoutes.GET_FUNDING_AWARDS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(awardsListFailed());
      done();
    } else {
      dispatch(
        awardsListSuccess({
          awards: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const getFunding_StatusLogic = createLogic({
  type: AwardsActions.REQUEST_FUNDING_STATUS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FUNDING_STATUS.service,
      ApiRoutes.GET_FUNDING_STATUS.url,
      ApiRoutes.GET_FUNDING_STATUS.method,
      ApiRoutes.GET_FUNDING_STATUS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(funding_statusListFailed());
      done();
    } else {
      dispatch(
        funding_statusListSuccess({
          funding_status: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const viewAwardsLogic = createLogic({
  type: AwardsActions.VIEW_AWARDS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_AWARDS.service,
      ApiRoutes.VIEW_AWARDS.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_AWARDS.method,
      ApiRoutes.VIEW_AWARDS.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(viewAwardsFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewAwardsSuccess({
          awardsData: responseData.data,
          fundingAnalysis: responseData.fundingAnalysis,
          fundingTransaction: responseData.fundingTransaction,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const exportAwardsLogic = createLogic({
  type: AwardsActions.EXPORT_CSV_AWARDS_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_AWARDS.service,
      ApiRoutes.EXPORT_CSV_AWARDS.url,
      ApiRoutes.EXPORT_CSV_AWARDS.method,
      ApiRoutes.EXPORT_CSV_AWARDS.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportAwardsFailed(messages[0]));
      done();
    } else {
      dispatch(exportAwardsSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const exportViewAwardsLogic = createLogic({
  type: AwardsActions.EXPORT_VIEW_AWARDS_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_AWARDS.service,
      ApiRoutes.EXPORT_VIEW_AWARDS.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_VIEW_AWARDS.method,
      ApiRoutes.EXPORT_VIEW_AWARDS.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewAwardsFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewAwardsSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});
/**
 *
 */

export const AwardsLogics = [
  getAwardsLogic,
  getFundingAwardsLogic,
  viewAwardsLogic,
  exportAwardsLogic,
  exportViewAwardsLogic,
  getFunding_StatusLogic,
];
