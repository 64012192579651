/** @format */

export const EnviornmentType = {
  DEV: "development",
  PROD: "production",
};

export const env = process.env.NODE_ENV || EnviornmentType.DEV;

export const AppConfig = {
  FILES_ENDPOINT: process.env.REACT_APP_FILES_ENDPOINT,
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  APP_ENDPOINT: process.env.REACT_APP_ENDPOINT,
  API_VERSION: process.env.REACT_APP_API_VERSION,
  SOCKET_ENDPOINT: process.env.REACT_APP_SOCKET_ENDPOINT,
};
