/** @format */

import { handleActions } from "redux-actions";
import { VendorActions } from "../actions";
import { VendorInitialStates } from "../states";

export const VendorReducer = handleActions(
  {
    [VendorActions.REQUEST_VENDOR_LIST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      vendor: [],
    }),
    [VendorActions.VENDOR_LIST_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      vendor: action.payload.vendor,
      isEmpty: action.payload.isEmpty,
      totalRecords: action.payload.totalRecords,
    }),
    [VendorActions.VENDOR_LIST_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [VendorActions.VIEW_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: true,
      vendorData: {},
    }),
    [VendorActions.VIEW_VENDOR_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: false,
      vendorData: action.payload.vendorData,
    }),
    [VendorActions.VIEW_VENDOR_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: false,
      error: true,
    }),
    [VendorActions.EXPORT_CSV_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [VendorActions.EXPORT_CSV_VENDOR_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [VendorActions.EXPORT_CSV_VENDOR_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [VendorActions.EXPORT_VIEW_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
      viewVendor: [],
    }),
    [VendorActions.EXPORT_VIEW_VENDOR_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      viewVendor: action.payload.viewVendor,
      totalRecords: action.payload.totalRecords,
    }),
    [VendorActions.EXPORT_VIEW_VENDOR_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),

    [VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [VendorActions.REQUEST_LIKED_VENDOR_LIST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLikedLoading: true,
      likedVendor: [],
    }),
    [VendorActions.LIKED_VENDOR_LIST_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLikedLoading: false,
      likedVendor: action.payload.likedVendor,
      isEmpty: action.payload.isEmpty,
      totalLikedRecords: action.payload.totalLikedRecords,
    }),
    [VendorActions.LIKED_VENDOR_LIST_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLikedLoading: false,
      error: true,
    }),

    [VendorActions.REQUEST_VENDOR_FUNDING_ANALYSIS_LIST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: true,
      fundingData: [],
      fundingGraphData: [],
    }),
    [VendorActions.VENDOR_FUNDING_ANALYSIS_LIST_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      fundingData: action.payload.fundingData,
      fundingGraphData: action.payload.fundingGraphData,
      totalDollarsObligated: action.payload.totalDollarsObligated,
    }),
    [VendorActions.VENDOR_FUNDING_ANALYSIS_LIST_FAILURE]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isfundingLoading: false,
      error: true,
    }),
    [VendorActions.VENDOR_LIKE_UNLIKE_SUCCESS]: (
      state = VendorInitialStates,
      action
    ) => {
      let vendor = state.vendor;
      let vendorData = state.vendorData;
      let vendorIndex = vendor.findIndex(x => x._id === action.payload.vendorId);
      if(vendorIndex !== -1) {
        vendor[vendorIndex].isFollowed = action.payload.status;
      }
      if (Object.keys(vendorData).length !== 0) {
        if (vendorData._id.toString() === action.payload.vendorId.toString()) {
          vendorData.isFollowed = action.payload.status;
        }
      }
      return ({
        ...state,
        vendor,
        vendorData,
        isLikedLoading: false,
      })
    },
    [VendorActions.LIKE_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLikedLoading: true,
    }),
    [VendorActions.UNLIKE_VENDOR_REQUEST]: (
      state = VendorInitialStates,
      action
    ) => ({
      ...state,
      isLikedLoading: true,
    }),
  },
  VendorInitialStates
);
