import { createAction } from 'redux-actions'

export const PscActions = {
  REQUEST_PSC_LIST: 'Request to fetch Psc list',
  PSC_LIST_SUCCESS: 'Psc list fetched successfully',
  PSC_LIST_FAILURE: 'Failed to fetched Psc list',

  VIEW_PSC_REQUEST: 'Request to view Psc',
  VIEW_PSC_SUCCESS: 'Psc details fetched successfully',
  VIEW_PSC_FAILURE: 'Failed to fetch psc details',

  EXPORT_CSV_PSC_REQUEST: 'Request to export csv psc',
  EXPORT_CSV_PSC_SUCCESS: 'psc export successfully',
  EXPORT_CSV_PSC_FAILURE: 'psc export Failed',

  EXPORT_VIEW_PSC_REQUEST: 'Request to export view psc',
  EXPORT_VIEW_PSC_SUCCESS: 'psc export view successfully',
  EXPORT_VIEW_PSC_FAILURE: 'psc export view Failed',

  REQUEST_PSC_HIERARCHY_LIST: 'Request to fetch Psc Hierarchy list',
  PSC_HIERARCHY_LIST_SUCCESS: 'Psc Hierarchy list fetched successfully',
  PSC_HIERARCHY_LIST_FAILURE: 'Failed to fetched Psc Hierarchy list',

  REQUEST_PSC_FUNDING_ANALYSIS_LIST:
    'Request to fetch Psc funding Analysis list',
  PSC_FUNDING_ANALYSIS_LIST_SUCCESS:
    'Psc funding Analysis list fetched successfully',
  PSC_FUNDING_ANALYSIS_LIST_FAILURE:
    'Failed to fetched Psc funding Analysis list'
}

// List Psc actions
export const requestPscList = createAction(PscActions.REQUEST_PSC_LIST)
export const pscListSuccess = createAction(PscActions.PSC_LIST_SUCCESS)
export const pscListFailed = createAction(PscActions.PSC_LIST_FAILURE)

// View Psc actions
export const viewPscRequest = createAction(PscActions.VIEW_PSC_REQUEST)
export const viewPscSuccess = createAction(PscActions.VIEW_PSC_SUCCESS)
export const viewPscFailed = createAction(PscActions.VIEW_PSC_FAILURE)

export const exportPscRequest = createAction(PscActions.EXPORT_CSV_PSC_REQUEST)
export const exportPscSuccess = createAction(PscActions.EXPORT_CSV_PSC_SUCCESS)
export const exportPscFailed = createAction(PscActions.EXPORT_CSV_PSC_FAILURE)

export const exportViewPscRequest = createAction(PscActions.EXPORT_VIEW_PSC_REQUEST)
export const exportViewPscSuccess = createAction(PscActions.EXPORT_VIEW_PSC_SUCCESS)
export const exportViewPscFailed = createAction(PscActions.EXPORT_VIEW_PSC_FAILURE)

// List Psc Hierarchy List actions
export const requestPscHierarchyList = createAction(
  PscActions.REQUEST_PSC_HIERARCHY_LIST
)
export const pscHierarchyListSuccess = createAction(
  PscActions.PSC_HIERARCHY_LIST_SUCCESS
)
export const pscHierarchyListFailed = createAction(
  PscActions.PSC_HIERARCHY_LIST_FAILURE
)

// List Psc funding analysis actions
export const requestPscFundingAnalysisList = createAction(
  PscActions.REQUEST_PSC_FUNDING_ANALYSIS_LIST
)
export const pscFundingAnalysisListSuccess = createAction(
  PscActions.PSC_FUNDING_ANALYSIS_LIST_SUCCESS
)
export const pscFundingAnalysisListFailed = createAction(
  PscActions.PSC_FUNDING_ANALYSIS_LIST_FAILURE
)
