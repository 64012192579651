/** @format */

import { handleActions } from "redux-actions";
import { SavedSearchActions } from "../actions";
import { SavedSearchInitialStates } from "../states";

export const SavedSearchReducer = handleActions(
  {
    [SavedSearchActions.REQUEST_ADD_SAVED_SEARCH]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),

    [SavedSearchActions.ADD_SAVED_SEARCH_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),

    [SavedSearchActions.ADD_SAVED_SEARCH_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [SavedSearchActions.REQUEST_SAVED_SEARCH_LIST]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      savedSearch: [],
    }),
    [SavedSearchActions.SAVED_SEARCH_LIST_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      savedSearch: action.payload.savedSearch,
      totalRecords: action.payload.totalRecords,
    }),
    [SavedSearchActions.SAVED_SEARCH_LIST_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [SavedSearchActions.UPDATE_SAVED_SEARCH_REQUEST]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),
    [SavedSearchActions.UPDATE_SAVED_SEARCH_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),
    [SavedSearchActions.UPDATE_SAVED_SEARCH_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmitting: false,
      isAdded: false,
      error: true,
    }),

    [SavedSearchActions.REQUEST_ADD_SEARCH]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: true,
      isAddedSearch: false,
      searchIdvalue: [],
    }),

    [SavedSearchActions.ADD_SEARCH_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: false,
      isAddedSearch: true,
      searchIdvalue: action.payload.searchIdvalue,
    }),

    [SavedSearchActions.ADD_SEARCH_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: false,
      error: true,
    }),

    [SavedSearchActions.REQUEST_SEARCH_LIST]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSearch: true,
    }),
    [SavedSearchActions.SEARCH_LIST_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSearch: false,
      searchData: action.payload.searchData,
    }),
    [SavedSearchActions.SEARCH_LIST_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isLoadingSearch: false,
      error: true,
    }),

    [SavedSearchActions.UPDATE_SEARCH_REQUEST]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: true,
      isAddedSearch: false,
    }),
    [SavedSearchActions.UPDATE_SEARCH_SUCCESS]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: false,
      isAddedSearch: true,
    }),
    [SavedSearchActions.UPDATE_SEARCH_FAILURE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingSearch: false,
      isAddedSearch: false,
      error: true,
    }),

    [SavedSearchActions.RESET_SEARCH_ID_VALUE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      ...action.payload,
    }),

    [SavedSearchActions.RESET_SEARCH_VALUE]: (
      state = SavedSearchInitialStates,
      action
    ) => ({
      ...state,
      savedSearch: {},
      searchIdvalue: '',
      searchData: [],
    }),
  },
  SavedSearchInitialStates
);
