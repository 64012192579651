/** @format */

import { createAction } from "redux-actions";

export const ModelActions = {
  MODAL_TOGGLE_REQUEST: "Toggle modal Request!",
};

export const modalToggleRequest = createAction(
  ModelActions.MODAL_TOGGLE_REQUEST
);

export const SidebarActions = {
  SET_SIDEBAR_SHOW: "SET_SIDEBAR_SHOW",
};

export const sidebarToggleRequest = createAction(
  SidebarActions.SET_SIDEBAR_SHOW
);
