/** @format */

import { handleActions } from "redux-actions";
import { FederalPeopleActions } from "../actions";
import { FederalPeopleInitialStates } from "../states";

export const FederalPeopleReducer = handleActions(
  {
    [FederalPeopleActions.REQUEST_FEDERALPEOPLE_LIST]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      federalpeople: [],
    }),
    [FederalPeopleActions.FEDERALPEOPLE_LIST_SUCCESS]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      federalpeople: action.payload.federalpeople,
      isEmpty: action.payload.isEmpty,
      totalRecords: action.payload.totalRecords,
    }),
    [FederalPeopleActions.FEDERALPEOPLE_LIST_FAILURE]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [FederalPeopleActions.VIEW_FEDERALPEOPLE_REQUEST]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      federalpeopleData: {},
    }),
    [FederalPeopleActions.VIEW_FEDERALPEOPLE_SUCCESS]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      federalpeopleData: action.payload.federalpeopleData,
    }),
    [FederalPeopleActions.VIEW_FEDERALPEOPLE_FAILURE]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_REQUEST]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_SUCCESS]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_FAILURE]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_REQUEST]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
      viewFederalPeople: [],
    }),
    [FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_SUCCESS]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      viewFederalPeople: action.payload.viewFederalPeople,
      totalRecords: action.payload.totalRecords,
    }),
    [FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_FAILURE]: (
      state = FederalPeopleInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),
  },
  FederalPeopleInitialStates
);
