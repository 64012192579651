import { handleActions } from "redux-actions";

import { AuthInitialStates, ResetPasswordInitialStates } from "./../states";
import { AuthActions } from "../actions";

export const AuthReducer = handleActions(
  {
    [AuthActions.REQUEST_LOGIN]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: true,
      loggedIn: false,
    }),
    [AuthActions.LOGIN_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: false,
      isLoading: false,
    }),
    [AuthActions.LOGOUT_REQUEST]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: false,
      isLoading: false,
    }),

    [AuthActions.USER_SIGNUP_REQUEST]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: true,
    }),
    [AuthActions.USER_SIGNUP_SUCCESS]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
    }),
    [AuthActions.USER_SIGNUP_FAILURE]: (state = AuthInitialStates, action) => ({
      ...state,
      isLoading: false,
    }),

    [AuthActions.LINK_VERIFICATION_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isLinkVerified: true,
    }),
    [AuthActions.LINK_VERIFICATION_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isLinkVerified: true,
      data: action.payload,
    }),
    [AuthActions.LINK_VERIFICATION_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isLinkVerified: false,
    }),

    [AuthActions.REQUEST_FORGOT_PASSWORD]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isForgetPasswordLoading: true,
    }),

    [AuthActions.FORGOT_PASSWORD_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isForgetPasswordLoading: false,
    }),

    [AuthActions.FORGOT_PASSWORD_FAILURE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isForgetPasswordLoading: false,
    }),

    [AuthActions.GET_USER_DETAILS]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.USER_DETAILS_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      profileUpdating: false,
      isAuthenticated: true,
    }),

    [AuthActions.USER_DETAILS_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      loggedIn: true,
      isLoading: false,
      user: action.payload.user,
      profileUpdating: false,
      isAuthenticated: false,
    }),

    [AuthActions.CHANGE_PASSWORD_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: true,
      passwordUpdated: false,
    }),
    [AuthActions.CHANGE_PASSWORD_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
      passwordUpdated: true,
    }),
    [AuthActions.CHANGE_PASSWORD_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      passwordUpdating: false,
    }),
    [AuthActions.REQUEST_UPDATE_PROFILE]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: true,
    }),
    [AuthActions.UPDATE_PROFILE_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      profileUpdating: false,
    }),

    [AuthActions.UPDATE_USER_STATUS_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      isUpdated: false,
    }),
    [AuthActions.UPDATE_USER_STATUS_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      isUpdated: true,
    }),

    [AuthActions.REQUEST_ADD_CARD]: (state = AuthInitialStates, action) => ({
      ...state,
      isSubmitting: true,
      isAdded: false,
    }),

    [AuthActions.ADD_CARD_SUCCESS]: (state = AuthInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      isAdded: true,
    }),

    [AuthActions.ADD_CARD_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      isSubmitting: false,
      error: true,
    }),

    [AuthActions.REQUEST_CARD_LIST]: (state = AuthInitialStates, action) => ({
      ...state,
      isCardLoading: true,
    }),
    [AuthActions.CARD_LIST_SUCCESS]: (state = AuthInitialStates, action) => ({
      ...state,
      isCardLoading: false,
      cards: action.payload.cards,
    }),
    [AuthActions.CARD_LIST_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      isCardLoading: false,
      error: true,
    }),

    [AuthActions.REQUEST_INVITE_USER]: (state = AuthInitialStates, action) => ({
      ...state,
      isInviting: true,
      isInvited: false,
    }),

    [AuthActions.INVITE_USER_SUCCESS]: (state = AuthInitialStates, action) => ({
      ...state,
      isInviting: false,
      isInvited: true,
    }),

    [AuthActions.INVITE_USER_FAILED]: (state = AuthInitialStates, action) => ({
      ...state,
      isInviting: false,
      error: true,
    }),

    [AuthActions.REQUEST_INVITE_USER_LIST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isInviteLoading: true,
    }),
    [AuthActions.INVITE_USER_LIST_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isInviteLoading: false,
      inviteUsers: action.payload.inviteUsers,
    }),
    [AuthActions.INVITE_USER_LIST_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isInviteLoading: false,
      error: true,
    }),

    [AuthActions.REQUEST_SUBSCRIPTION_LIST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isSubscriptionLoading: true,
    }),
    [AuthActions.SUBSCRIPTION_LIST_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isSubscriptionLoading: false,
      data: action.payload.data,
    }),
    [AuthActions.SUBSCRIPTION_LIST_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isSubscriptionLoading: false,
      error: true,
    }),

    [AuthActions.CANCEL_SUBSCRIPTION_REQUEST]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isCancelSubscriptionLoading: true,
    }),
    [AuthActions.CANCEL_SUBSCRIPTION_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isCancelSubscriptionLoading: false,
    }),
    [AuthActions.CANCEL_SUBSCRIPTION_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isCancelSubscriptionLoading: false,
    }),

    [AuthActions.GET_GUEST_DATA]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      guestData: {},
    }),
    [AuthActions.GUEST_DATA_SUCCESS]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      guestData: action.payload.guestData,
    }),
    [AuthActions.GUEST_DATA_FAILED]: (
      state = AuthInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
  },
  AuthInitialStates
);

export const ResetPasswordReducer = handleActions(
  {
    [AuthActions.LINK_VERIFICATION_PASSWORD_REQUEST]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: true,
      isPasswordLinkVerified: true,
    }),
    [AuthActions.LINK_VERIFICATION_PASSWORD_SUCCESS]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: false,
      isPasswordLinkVerified: true,
      userData: action.payload,
    }),
    [AuthActions.LINK_VERIFICATION_PASSWORD_FAILURE]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      verifyTokenLoading: false,
      isPasswordLinkVerified: false,
      message: action.payload.message,
    }),
    [AuthActions.SET_PASSWORD_REQUEST]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: true,
      passwordSet: false,
    }),
    [AuthActions.SET_PASSWORD_SUCCESS]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: false,
      passwordSet: true,
    }),
    [AuthActions.SET_PASSWORD_FAILURE]: (
      state = ResetPasswordInitialStates,
      action
    ) => ({
      ...state,
      isPasswordLoading: false,
      passwordSet: false,
    }),
  },
  ResetPasswordInitialStates
);
