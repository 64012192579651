/** @format */

export const OpportunityInitialStates = {
  isLoading: true,
  opportunity: [],
  opportunityData: {},
  isSubmittingToPipeline: false,
  isAddedToPipeline: false,

  isLoadingNote: false,
  isSubmittingNote: false,
  isAddedNote: false,
  isUpdatedNote: false,
  error: false,
  notes: {},
  isDownloaded: false,

  isFollowedLoading: false,
  followedOpportunity: [],

  searchFlag: false,

  isLoadingPsc: false,
  pscList: [],

  isLoadingNaisc: false,
  naiscList: [],

  isLoadingAgency: false,
  agencyList: [],

  isLoadingPerson: false,
  personList: [],

  isLoadingStack: false,
  opportunityStackData: [],
  opportunitySetAsideArr: [],
  opportunityTypeArr: [],
  opportunityStack: false,
  followedOpportunityStack: false,

  viewExportOpportunity: [],
  isExported: false
};
