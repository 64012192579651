import { combineReducers } from 'redux'

import { AuthReducer, ResetPasswordReducer } from './Auth'
import { ModalInfoReducer, SidebarReducer } from './Modal'
import { DashboardReducer } from './Dashboard'
import { PipelineReducer } from './Pipeline'
import { StageReducer } from './Stage'
import { OpportunityReducer } from './Opportunity'
import { AwardsReducer } from './Awards'
import { PscReducer } from './Psc'
import { NaicsReducer } from './Naics'
import { FederalAgenciesReducer } from './FederalAgencies'
import { FederalPeopleReducer } from './FederalPeople'
import { VendorReducer } from './Vendor'
import { SavedSearchReducer } from './SavedSearch'
import { GlobalSearchReducer } from './GlobalSearch'
import { NotificationReducer } from './Notification'

export const RootReducer = combineReducers({
  authReducer: AuthReducer,
  dashboardReducer: DashboardReducer,
  resetPasswordReducer: ResetPasswordReducer,
  modalInfoReducer: ModalInfoReducer,
  sidebarReducer: SidebarReducer,
  pipelineReducer: PipelineReducer,
  stageReducer: StageReducer,
  opportunityReducer: OpportunityReducer,
  awardsReducer: AwardsReducer,
  pscReducer: PscReducer,
  naicsReducer: NaicsReducer,
  federalAgenciesReducer: FederalAgenciesReducer,
  federalPeopleReducer: FederalPeopleReducer,
  vendorReducer: VendorReducer,
  savedSearchReducer: SavedSearchReducer,
  globalSearchReducer: GlobalSearchReducer,
  notificationReducer: NotificationReducer
})
