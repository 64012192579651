/** @format */

import { createLogic } from "redux-logic";
import {
  addSavedSearchFailed,
  addSavedSearchSuccess,
  deleteSavedSearchFailed,
  savedSearchListFailed,
  savedSearchListSuccess,
  SavedSearchActions,
  requestSavedSearchList,
  updateSavedSearchFailed,
  updateSavedSearchSuccess,
  addSearchFailed,
  addSearchSuccess,
  SearchListFailed,
  SearchListSuccess,
  updateSearchSuccess,
  updateSearchFailed,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const addSavedSearchLogic = createLogic({
  type: SavedSearchActions.REQUEST_ADD_SAVED_SEARCH,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SAVED_SEARCH.service,
      ApiRoutes.ADD_SAVED_SEARCH.url,
      ApiRoutes.ADD_SAVED_SEARCH.method,
      ApiRoutes.ADD_SAVED_SEARCH.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addSavedSearchSuccess());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addSavedSearchFailed());
      done();
    }
  },
});

/**
 *
 */
const getSavedSearchsLogic = createLogic({
  type: SavedSearchActions.REQUEST_SAVED_SEARCH_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SAVED_SEARCH.service,
      ApiRoutes.GET_SAVED_SEARCH.url,
      ApiRoutes.GET_SAVED_SEARCH.method,
      ApiRoutes.GET_SAVED_SEARCH.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }

      dispatch(savedSearchListFailed());
      done();
    } else {
      dispatch(
        savedSearchListSuccess({
          savedSearch: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const updateSavedSearchLogic = createLogic({
  type: SavedSearchActions.UPDATE_SAVED_SEARCH_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SAVED_SEARCH.service,
      ApiRoutes.UPDATE_SAVED_SEARCH.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_SAVED_SEARCH.method,
      ApiRoutes.UPDATE_SAVED_SEARCH.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateSavedSearchSuccess());
      dispatch(requestSavedSearchList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(updateSavedSearchFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteSavedSearchLogic = createLogic({
  type: SavedSearchActions.DELETE_SAVED_SEARCH_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SAVED_SEARCH.service,
      ApiRoutes.DELETE_SAVED_SEARCH.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_SAVED_SEARCH.method,
      ApiRoutes.DELETE_SAVED_SEARCH.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      dispatch(deleteSavedSearchFailed(messages[0]));
      done();
    }
    dispatch(requestSavedSearchList());
    dispatch(messages[0]);
    showSuccess(messages[0]);
    done();
  },
});

/**
 *
 */
const addSearchLogic = createLogic({
  type: SavedSearchActions.REQUEST_ADD_SEARCH,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SEARCH.service,
      ApiRoutes.ADD_SEARCH.url,
      ApiRoutes.ADD_SEARCH.method,
      ApiRoutes.ADD_SEARCH.authenticate,
      undefined,
      { data: action.payload }
    );
    if (!isError) {
      dispatch(
        addSearchSuccess({
          searchIdvalue: responseData.search._id,
        })
      );
      done();
    } else {
      dispatch(addSearchFailed());
      done();
    }
  },
});

/**
 *
 */
const getSearchLogic = createLogic({
  type: SavedSearchActions.REQUEST_SEARCH_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_SEARCH.service,
      ApiRoutes.GET_SEARCH.url.replace(":id", action.payload.id),
      ApiRoutes.GET_SEARCH.method,
      ApiRoutes.GET_SEARCH.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      dispatch(SearchListFailed());
      done();
    } else {
      dispatch(
        SearchListSuccess({
          searchData: responseData.search.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const updateSearchLogic = createLogic({
  type: SavedSearchActions.UPDATE_SEARCH_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_SEARCH.service,
      ApiRoutes.UPDATE_SEARCH.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_SEARCH.method,
      ApiRoutes.UPDATE_SEARCH.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateSearchSuccess());

      done();
    } else {
      dispatch(updateSearchFailed());
      done();
    }
  },
});

export const SavedSearchLogics = [
  addSavedSearchLogic,
  getSavedSearchsLogic,
  deleteSavedSearchLogic,
  updateSavedSearchLogic,
  addSearchLogic,
  getSearchLogic,
  updateSearchLogic,
];
