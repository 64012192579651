/** @format */

export const NaicsInitialStates = {
  isLoading: false,
  naics: [],
  naicsData: {},
  isDownloaded: false,

  isNaicsHierarchyLoading: false,
  naicsHierarchy: [],

  isLoadingparentNaisc: false,
  parentNaicsList: [],

  isfundingLoading: false,
  fundingData: [],
  totalDollarsObligated: "",
  fundingGraphData: [],

  isExported: true,
  viewNaics: [],
};
