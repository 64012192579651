/** @format */

export const AwardsInitialStates = {
  isLoading: true,
  awards: [],
  awardsData: {},
  funding_status: [],
  fundingAnalysis: {},
  fundingTransaction: {},
  isDownloaded: false,

  isExported: false,
  viewExportAward: [],
  awardSetAside: [],
};
