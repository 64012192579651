/** @format */

import { handleActions } from "redux-actions";
import { DashboardActions } from "../actions";
import { DashboardInitialStates } from "../states";

export const DashboardReducer = handleActions(
  {
    [DashboardActions.REQUEST_OPPORTUNITIES_DUE_LIST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [DashboardActions.OPPORTUNITIES_DUE_LIST_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      opportunitiesDue: action.payload.opportunitiesDue,
    }),
    [DashboardActions.OPPORTUNITIES_DUE_LIST_FAILURE]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [DashboardActions.REQUEST_PIPELINE_OVERVIEW_LIST]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isPipelineLoading: true,
    }),
    [DashboardActions.PIPELINE_OVERVIEW_LIST_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isPipelineLoading: false,
      pipelineOverview: action.payload.pipelineOverview,
    }),
    [DashboardActions.PIPELINE_OVERVIEW_LIST_FAILURE]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isPipelineLoading: false,
      error: true,
    }),

    [DashboardActions.REQUEST_CARD_COUNT]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: true,
    }),
    [DashboardActions.CARD_COUNT_SUCCESS]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: false,
      countData: action.payload.countData,
    }),
    [DashboardActions.CARD_COUNT_FAILURE]: (
      state = DashboardInitialStates,
      action
    ) => ({
      ...state,
      isCountLoading: false,
      error: true,
    }),
  },
  DashboardInitialStates
);
