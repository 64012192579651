/** @format */
import { handleActions } from "redux-actions";
import { ModelActions, SidebarActions } from "../actions";
import { ModalInitialStates, SidebarInitialStates } from "../states";

export const ModalInfoReducer = handleActions(
  {
    [ModelActions.MODAL_TOGGLE_REQUEST]: (
      state = ModalInitialStates,
      action
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  ModalInitialStates
);

export const SidebarReducer = handleActions(
  {
    [SidebarActions.SET_SIDEBAR_SHOW]: (
      state = SidebarInitialStates,
      action
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
  SidebarInitialStates
);
