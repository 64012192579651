/** @format */

import { handleActions } from "redux-actions";
import { AwardsActions } from "../actions";
import { AwardsInitialStates } from "../states";

export const AwardsReducer = handleActions(
  {
    [AwardsActions.REQUEST_AWARDS_LIST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      awards: [],
      awardSetAside: [],
    }),
    [AwardsActions.REQUEST_FUNDING_AWARDS_LIST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      awards: [],
      awardSetAside: [],
    }),
    [AwardsActions.AWARDS_LIST_SUCCESS]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      awards: action.payload.awards,
      awardSetAside: action.payload.awardSetAside,
      totalRecords: action.payload.totalRecords,
    }),
    [AwardsActions.AWARDS_LIST_FAILURE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [AwardsActions.VIEW_AWARDS_REQUEST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      awardsData: {},
    }),
    [AwardsActions.VIEW_AWARDS_SUCCESS]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      awardsData: action.payload.awardsData,
      fundingAnalysis: action.payload.fundingAnalysis,
      fundingTransaction: action.payload.fundingTransaction,
    }),
    [AwardsActions.VIEW_AWARDS_FAILURE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [AwardsActions.EXPORT_CSV_AWARDS_REQUEST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [AwardsActions.EXPORT_CSV_AWARDS_SUCCESS]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [AwardsActions.EXPORT_CSV_AWARDS_FAILURE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [AwardsActions.EXPORT_VIEW_AWARDS_REQUEST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
      viewExportAward: [],
    }),
    [AwardsActions.EXPORT_VIEW_AWARDS_SUCCESS]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      viewExportAward: action.payload.viewAward,
      totalRecords: action.payload.totalRecords,
    }),
    [AwardsActions.EXPORT_VIEW_AWARDS_FAILURE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),

    [AwardsActions.REQUEST_FUNDING_STATUS_LIST]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
    }),
    [AwardsActions.FUNDING_STATUS_LIST_SUCCESS]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      funding_status: action.payload.funding_status,
      totalRecords: action.payload.totalRecords,
    }),
    [AwardsActions.FUNDING_STATUS_LIST_FAILURE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),
    [AwardsActions.RESET_AWARD_VALUE]: (
      state = AwardsInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      awards: [],
      awardsData: {},
    }),
  },
  AwardsInitialStates
);
