/** @format */

import { createAction } from "redux-actions";

export const PipelineActions = {
  REQUEST_ADD_PIPELINE: "Request to add pipeline",
  ADD_PIPELINE_SUCCESS: "Pipeline added successfully",
  ADD_PIPELINE_FAILURE: "Failed to add pipeline",

  REQUEST_PIPELINES_LIST: "Request to fetch pipeline list",
  PIPELINES_LIST_SUCCESS: "Pipeline list fetched successfully",
  PIPELINES_LIST_FAILURE: "Failed to fetched pipeline list",

  UPDATE_PIPELINE_REQUEST: "Request to update pipeline",
  UPDATE_PIPELINE_SUCCESS: "Pipeline updated successfully",
  UPDATE_PIPELINE_FAILURE: "Failed to update pipeline",

  VIEW_PIPELINE_REQUEST: "Request to view pipeline",
  VIEW_PIPELINE_SUCCESS: "Pipeline details fetched successfully",
  VIEW_PIPELINE_FAILURE: "Failed to fetch pipeline details",

  DELETE_PIPELINE_REQUEST: "Request to delete pipeline",
  DELETE_PIPELINE_FAILURE: "Failed to delete pipeline ",

  EXPORT_CSV_PIPELINE_REQUEST: "Request to export csv pipeline",
  EXPORT_CSV_PIPELINE_SUCCESS: "Pipeline export successfully",

  UPDATE_OPPORTUNITY_STAGE_REQUEST: "Request to update opportunity stage",
  UPDATE_OPPORTUNITY_STAGE_SUCCESS: "Opportunity stage updated successfully",
  UPDATE_OPPORTUNITY_STAGE_FAILURE:
    "Failed to  update opportunity stage updated",

  REORDER_OPPORTUNITY_STAGE_REQUEST: "Request to reorder opportunity stage",
  REORDER_OPPORTUNITY_STAGE_SUCCESS: "Opportunity stage reorder successfully",
  REORDER_OPPORTUNITY_STAGE_FAILURE: "Failed to  reorder opportunity stage",

  DELETE_STAGE_OPPORTUNITY_REQUEST:
    "Request to delete stage opportunity from pipeline",
  DELETE_STAGE_OPPORTUNITY_SUCCESS: "Delete stage opportunity successfully",
};

// Add pipeline actions
export const requestAddPipeline = createAction(
  PipelineActions.REQUEST_ADD_PIPELINE
);
export const addPipelineSuccess = createAction(
  PipelineActions.ADD_PIPELINE_SUCCESS
);
export const addPipelineFailed = createAction(
  PipelineActions.ADD_PIPELINE_FAILURE
);

// List pipeline actions
export const requestPipelineList = createAction(
  PipelineActions.REQUEST_PIPELINES_LIST
);
export const pipelineListSuccess = createAction(
  PipelineActions.PIPELINES_LIST_SUCCESS
);
export const pipelineListFailed = createAction(
  PipelineActions.PIPELINES_LIST_FAILURE
);

// Update pipeline actions
export const updatePipelineRequest = createAction(
  PipelineActions.UPDATE_PIPELINE_REQUEST
);
export const updatePipelineSuccess = createAction(
  PipelineActions.UPDATE_PIPELINE_SUCCESS
);
export const updatePipelineFailed = createAction(
  PipelineActions.UPDATE_PIPELINE_FAILURE
);

// View pipeline actions
export const viewPipelineRequest = createAction(
  PipelineActions.VIEW_PIPELINE_REQUEST
);
export const viewPipelineSuccess = createAction(
  PipelineActions.VIEW_PIPELINE_SUCCESS
);
export const viewPipelineFailed = createAction(
  PipelineActions.VIEW_PIPELINE_FAILURE
);

// Delete pipeline actions
export const deletePipelineRequest = createAction(
  PipelineActions.DELETE_PIPELINE_REQUEST
);

export const deletePipelineFailed = createAction(
  PipelineActions.DELETE_PIPELINE_FAILURE
);

export const exportPipelineRequest = createAction(
  PipelineActions.EXPORT_CSV_PIPELINE_REQUEST
);
export const exportPipelineSuccess = createAction(
  PipelineActions.EXPORT_CSV_PIPELINE_SUCCESS
);
// Update stage actions
export const requestUpdateOpportunityStage = createAction(
  PipelineActions.UPDATE_OPPORTUNITY_STAGE_REQUEST
);
export const updateOpportunityStageSuccess = createAction(
  PipelineActions.UPDATE_OPPORTUNITY_STAGE_SUCCESS
);
export const updateOpportunityStageFailed = createAction(
  PipelineActions.UPDATE_OPPORTUNITY_STAGE_FAILURE
);

// reorder opportunity  actions
export const requestReorderStage = createAction(
  PipelineActions.REORDER_OPPORTUNITY_STAGE_REQUEST
);
export const reorderStageSuccess = createAction(
  PipelineActions.REORDER_OPPORTUNITY_STAGE_SUCCESS
);
export const reorderStageFailed = createAction(
  PipelineActions.REORDER_OPPORTUNITY_STAGE_FAILURE
);

export const deleteStageOpportunityRequest = createAction(
  PipelineActions.DELETE_STAGE_OPPORTUNITY_REQUEST
);
export const deleteStageOpportunitySuccess = createAction(
  PipelineActions.DELETE_STAGE_OPPORTUNITY_SUCCESS
);
