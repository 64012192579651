/** @format */
import { createLogic } from 'redux-logic'
import { push } from 'react-router-redux'
import { AuthLogics } from './Auth'
import { DashboardLogics } from './Dashboard'
import { PipelineLogics } from './Pipeline'
import { StageLogics } from './Stage'
import { OpportunityLogics } from './Opportunity'
import { AwardsLogics } from './Awards'
import { PscLogics } from './Psc'
import { NaicsLogics } from './Naics'
import { FederalAgenciesLogics } from './FederalAgencies'
import { FederalPeopleLogics } from './FederalPeople'
import { VendorLogics } from './Vendor'
import { SavedSearchLogics } from './SavedSearch'
import { GlobalSearchLogics } from './GlobalSearch'
import { NotificationLogics } from './Notification'

export const redirectToLogic = createLogic({
  type: 'REDIRECT_TO',
  async process (data, dispatch, done) {
    const action = data.action
    dispatch(push(action.payload.path))
    done()
  }
})

export const AllLogics = [
  ...AuthLogics,
  ...DashboardLogics,
  ...PipelineLogics,
  ...StageLogics,
  ...OpportunityLogics,
  ...AwardsLogics,
  ...PscLogics,
  ...NaicsLogics,
  ...FederalAgenciesLogics,
  ...FederalPeopleLogics,
  ...VendorLogics,
  ...SavedSearchLogics,
  ...GlobalSearchLogics,
  ...NotificationLogics,
  redirectToLogic
]
