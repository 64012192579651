/** @format */

import { createLogic } from "redux-logic";
import {
  exportFollowedVendorSuccess,
  exportFollowedVendorFailed,
  exportVendorSuccess,
  exportVendorFailed,
  exportViewVendorSuccess,
  exportViewVendorFailed,
  VendorActions,
  vendorListFailed,
  vendorListSuccess,
  requestLikedVendorList,
  // requestVendorList,
  LikedVendorListFailed,
  LikedVendorListSuccess,
  viewVendorFailed,
  viewVendorSuccess,
  vendorFundingAnalysisListSuccess,
  vendorFundingAnalysisListFailed,
  // viewVendorRequest,
  vendorLikeUnlikeSuccess,
  getUserDetails,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getVendorLogic = createLogic({
  type: VendorActions.REQUEST_VENDOR_LIST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_VENDOR.service,
      ApiRoutes.GET_VENDOR.url,
      ApiRoutes.GET_VENDOR.method,
      ApiRoutes.GET_VENDOR.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(vendorListFailed());
      done();
    } else {
      dispatch(
        vendorListSuccess({
          vendor: responseData.data,
          isEmpty: responseData.isEmpty,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const likeVendorLogic = createLogic({
  type: VendorActions.LIKE_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.LIKE_VENDOR.service,
      ApiRoutes.LIKE_VENDOR.url,
      ApiRoutes.LIKE_VENDOR.method,
      ApiRoutes.LIKE_VENDOR.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      done();
    } else {
      showSuccess(messages[0]);
      dispatch(vendorLikeUnlikeSuccess({ vendorId: action.payload.vendorId, status: true }));
      dispatch(getUserDetails());
      // dispatch(requestVendorList());
      dispatch(requestLikedVendorList());
      /* dispatch(
        viewVendorRequest({
          id: action.payload.cageCode,
        })
      ); */

      done();
    }
  },
});

/**
 *
 */
const unLikeVendorLogic = createLogic({
  type: VendorActions.UNLIKE_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNLIKE_VENDOR.service,
      ApiRoutes.UNLIKE_VENDOR.url.replace(":id", action.payload.vendorId),

      ApiRoutes.UNLIKE_VENDOR.method,
      ApiRoutes.UNLIKE_VENDOR.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      done();
    } else {
      showSuccess(messages[0]);
      dispatch(vendorLikeUnlikeSuccess({ vendorId: action.payload.vendorId, status: false }));
      dispatch(getUserDetails());
      // dispatch(requestVendorList());
      dispatch(requestLikedVendorList());
      /* dispatch(
        viewVendorRequest({
          id: action.payload.cageCode,
        })
      ); */
      done();
    }
  },
});

/**
 *
 */
const getLikedVendorLogic = createLogic({
  type: VendorActions.REQUEST_LIKED_VENDOR_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_LIKED_VENDOR.service,
      ApiRoutes.GET_LIKED_VENDOR.url,
      ApiRoutes.GET_LIKED_VENDOR.method,
      ApiRoutes.GET_LIKED_VENDOR.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(LikedVendorListFailed());
      done();
    } else {
      dispatch(
        LikedVendorListSuccess({
          likedVendor: responseData.data,
          isEmpty: responseData.isEmpty,
          totalLikedRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */

const viewVendorLogic = createLogic({
  type: VendorActions.VIEW_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_VENDOR.service,
      ApiRoutes.VIEW_VENDOR.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_VENDOR.method,
      ApiRoutes.VIEW_VENDOR.authenticate,
      undefined,
      // { federalPeopleSearch, searchGovermentFiles, contractAwardsSearch },
      undefined
    );

    if (isError) {
      dispatch(viewVendorFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewVendorSuccess({
          vendorData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const exportVendorLogic = createLogic({
  type: VendorActions.EXPORT_CSV_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_VENDOR.service,
      ApiRoutes.EXPORT_CSV_VENDOR.url,
      ApiRoutes.EXPORT_CSV_VENDOR.method,
      ApiRoutes.EXPORT_CSV_VENDOR.authenticate,
      action.payload,
      undefined,
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportVendorFailed(messages[0]));
      done();
    } else {
      dispatch(exportVendorSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const exportViewVendorLogic = createLogic({
  type: VendorActions.EXPORT_VIEW_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_VENDOR.service,
      ApiRoutes.EXPORT_VIEW_VENDOR.url.replace(":id", action.payload.id),

      ApiRoutes.EXPORT_VIEW_VENDOR.method,
      ApiRoutes.EXPORT_VIEW_VENDOR.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewVendorFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewVendorSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */
const exportFollowedVendorLogic = createLogic({
  type: VendorActions.EXPORT_CSV_FOLLOWED_VENDOR_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_FOLLOWED_CSV_VENDOR.service,
      ApiRoutes.EXPORT_FOLLOWED_CSV_VENDOR.url,
      ApiRoutes.EXPORT_FOLLOWED_CSV_VENDOR.method,
      ApiRoutes.EXPORT_FOLLOWED_CSV_VENDOR.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportFollowedVendorFailed(messages[0]));
      done();
    } else {
      dispatch(exportFollowedVendorSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

const getVendorFundingAnalysisLogic = createLogic({
  type: VendorActions.REQUEST_VENDOR_FUNDING_ANALYSIS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_VENDOR_FUNDING_ANALYSIS.service,
      ApiRoutes.GET_VENDOR_FUNDING_ANALYSIS.url,
      ApiRoutes.GET_VENDOR_FUNDING_ANALYSIS.method,
      ApiRoutes.GET_VENDOR_FUNDING_ANALYSIS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(vendorFundingAnalysisListFailed());
      done();
    } else {
      dispatch(
        vendorFundingAnalysisListSuccess({
          fundingData: responseData.data,
          fundingGraphData: responseData.graphData,
          totalDollarsObligated: responseData.totalDollarsObligated,
        })
      );
      done();
    }
  },
});

export const VendorLogics = [
  getVendorLogic,
  viewVendorLogic,
  getLikedVendorLogic,
  likeVendorLogic,
  unLikeVendorLogic,
  exportVendorLogic,
  exportViewVendorLogic,
  exportFollowedVendorLogic,
  getVendorFundingAnalysisLogic,
];
