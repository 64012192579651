import { createAction } from "redux-actions";

export const AwardsActions = {
  REQUEST_AWARDS_LIST: "Request to fetch Awards list",
  REQUEST_FUNDING_AWARDS_LIST: "Request to fetch Funding Awards list",
  AWARDS_LIST_SUCCESS: "Awards list fetched successfully",
  AWARDS_LIST_FAILURE: "Failed to fetched Awards list",

  REQUEST_FUNDING_STATUS_LIST: "Request to fetch Funding_Status list",
  FUNDING_STATUS_LIST_SUCCESS: "Funding_Status list fetched successfully",
  FUNDING_STATUS_LIST_FAILURE: "Failed to fetched Funding_Status list",

  VIEW_AWARDS_REQUEST: "Request to view Awards",
  VIEW_AWARDS_SUCCESS: "Awards details fetched successfully",
  VIEW_AWARDS_FAILURE: "Failed to fetch awards details",

  EXPORT_CSV_AWARDS_REQUEST: "Request to export csv awards",
  EXPORT_CSV_AWARDS_SUCCESS: "awards export successfully",
  EXPORT_CSV_AWARDS_FAILURE: "awards export Failed",

  EXPORT_VIEW_AWARDS_REQUEST: "Request to export view awards",
  EXPORT_VIEW_AWARDS_SUCCESS: "awards export view successfully",
  EXPORT_VIEW_AWARDS_FAILURE: "awards export view Failed",
  RESET_AWARD_VALUE: "Request to reset award value",
};

// List opportunity actions
export const requestAwardsList = createAction(AwardsActions.REQUEST_AWARDS_LIST);
export const requestFundingAwardsList = createAction(AwardsActions.REQUEST_FUNDING_AWARDS_LIST);
export const awardsListSuccess = createAction(AwardsActions.AWARDS_LIST_SUCCESS);
export const awardsListFailed = createAction(AwardsActions.AWARDS_LIST_FAILURE);

// List funding_status actions
export const requestFunding_StatusList = createAction(AwardsActions.REQUEST_FUNDING_STATUS_LIST);
export const funding_statusListSuccess = createAction(AwardsActions.FUNDING_STATUS_LIST_SUCCESS);
export const funding_statusListFailed = createAction(AwardsActions.FUNDING_STATUS_LIST_FAILURE);

// View opportunity actions
export const viewAwardsRequest = createAction(AwardsActions.VIEW_AWARDS_REQUEST);
export const viewAwardsSuccess = createAction(AwardsActions.VIEW_AWARDS_SUCCESS);
export const viewAwardsFailed = createAction(AwardsActions.VIEW_AWARDS_FAILURE);

export const exportAwardsRequest = createAction(AwardsActions.EXPORT_CSV_AWARDS_REQUEST);
export const exportAwardsSuccess = createAction(AwardsActions.EXPORT_CSV_AWARDS_SUCCESS);
export const exportAwardsFailed = createAction(AwardsActions.EXPORT_CSV_AWARDS_FAILURE);

export const exportViewAwardsRequest = createAction(AwardsActions.EXPORT_VIEW_AWARDS_REQUEST);
export const exportViewAwardsSuccess = createAction(AwardsActions.EXPORT_VIEW_AWARDS_SUCCESS);
export const exportViewAwardsFailed = createAction(AwardsActions.EXPORT_VIEW_AWARDS_FAILURE);
export const resetAwardValue = createAction(AwardsActions.RESET_AWARD_VALUE);