/** @format */

export const VendorInitialStates = {
  isLoading: false,
  vendor: [],
  vendorData: {},
  isDownloaded: false,

  isLikedLoading: false,
  likedVendor: [],

  isfundingLoading: false,
  fundingData: [],
  totalDollarsObligated: "",
  fundingGraphData: [],

  isExported: false,
  viewVendor: [],
};
