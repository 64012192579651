/** @format */

import { createAction } from "redux-actions";

export const StageActions = {
  UPDATE_STAGE_REQUEST: "Request to update stage",
  UPDATE_STAGE_SUCCESS: "Stage updated successfully",
  UPDATE_STAGE_FAILURE: "Failed to update stage",
};

// Update stage  actions
export const updateStageRequest = createAction(
  StageActions.UPDATE_STAGE_REQUEST
);
export const updateStageSuccess = createAction(
  StageActions.UPDATE_STAGE_SUCCESS
);
export const updateStageFailed = createAction(
  StageActions.UPDATE_STAGE_FAILURE
);
