/** @format */

import { createLogic } from "redux-logic";
import {
  cardCountFailed,
  cardCountSuccess,
  DashboardActions,
  opportunitiesDueListFailed,
  opportunitiesDueListSuccess,
  pipelineOverviewListFailed,
  pipelineOverviewListSuccess,
} from "../actions";
import { showError } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */

const getOpportunitiesDueLogic = createLogic({
  type: DashboardActions.REQUEST_OPPORTUNITIES_DUE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_DUE_OPPORTUNITIES.service,
      ApiRoutes.GET_DUE_OPPORTUNITIES.url,
      ApiRoutes.GET_DUE_OPPORTUNITIES.method,
      ApiRoutes.GET_DUE_OPPORTUNITIES.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(opportunitiesDueListFailed());
      done();
    } else {
      dispatch(
        opportunitiesDueListSuccess({
          opportunitiesDue: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

const getPipelineOverviewLogic = createLogic({
  type: DashboardActions.REQUEST_PIPELINE_OVERVIEW_LIST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PIPELINE_OVERVIEW.service,
      ApiRoutes.GET_PIPELINE_OVERVIEW.url,
      ApiRoutes.GET_PIPELINE_OVERVIEW.method,
      ApiRoutes.GET_PIPELINE_OVERVIEW.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(pipelineOverviewListFailed());
      done();
    } else {
      dispatch(
        pipelineOverviewListSuccess({
          pipelineOverview: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

const getCardCountLogic = createLogic({
  type: DashboardActions.REQUEST_CARD_COUNT,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CARD_COUNT.service,
      ApiRoutes.GET_CARD_COUNT.url,
      ApiRoutes.GET_CARD_COUNT.method,
      ApiRoutes.GET_CARD_COUNT.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(cardCountFailed());
      done();
    } else {
      dispatch(
        cardCountSuccess({
          countData: responseData.data,
        })
      );
      done();
    }
  },
});

export const DashboardLogics = [
  getOpportunitiesDueLogic,
  getPipelineOverviewLogic,
  getCardCountLogic,
];
