/** @format */

import { handleActions } from "redux-actions";
import { OpportunityActions } from "../actions";
import { OpportunityInitialStates } from "../states";

export const OpportunityReducer = handleActions(
  {
    [OpportunityActions.REQUEST_OPPORTUNITY_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      opportunity: [],
    }),
    [OpportunityActions.OPPORTUNITY_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      opportunity: action.payload.opportunity,
      isEmpty: action.payload.isEmpty,
      totalRecords: action.payload.totalRecords,
      searchFlag: true,
      opportunitySetAsideArr: action.payload.opportunitySetAside,
      opportunityTypeArr: action.payload.opportunityType,
      opportunityStack: action.payload.opportunityStack,
    }),
    [OpportunityActions.OPPORTUNITY_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoading: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FOLLOWED_OPPORTUNITY_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isFollowedLoading: true,
    }),
    [OpportunityActions.FOLLOWED_OPPORTUNITY_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isFollowedLoading: false,
      followedOpportunity: action.payload.followedOpportunity,
      isEmpty: action.payload.isEmpty,
      totalFollowedRecords: action.payload.totalFollowedRecords,
      followedOpportunityStack: action.payload.followedOpportunityStack,
      opportunitySetAsideArr: action.payload.opportunitySetAside,
      opportunityTypeArr: action.payload.opportunityType,
    }),
    [OpportunityActions.FOLLOWED_OPPORTUNITY_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isFollowedLoading: false,
      error: true,
    }),

    [OpportunityActions.VIEW_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: true,
      opportunityData: {},
    }),
    [OpportunityActions.VIEW_OPPORTUNITY_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: false,
      opportunityData: action.payload.opportunityData,
    }),
    [OpportunityActions.VIEW_OPPORTUNITY_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isViewLoading: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_ADD_TO_PIPELINE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingToPipeline: true,
      isAddedToPipeline: false,
    }),

    [OpportunityActions.ADD_TO_PIPELINE_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingToPipeline: false,
      isAddedToPipeline: true,
    }),

    [OpportunityActions.ADD_TO_PIPELINE_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingToPipeline: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_ADD_NOTE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: true,
      isAddedNote: false,
    }),

    [OpportunityActions.ADD_NOTE_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: false,
      isAddedNote: true,
    }),

    [OpportunityActions.ADD_NOTE_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_NOTE_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNote: true,
    }),
    [OpportunityActions.NOTE_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNote: false,
      notes: action.payload.notes,
    }),
    [OpportunityActions.NOTE_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNote: false,
      error: true,
    }),

    [OpportunityActions.UPDATE_NOTE_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: true,
      isAddedNote: false,
    }),
    [OpportunityActions.UPDATE_NOTE_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: false,
      isAddedNote: true,
    }),
    [OpportunityActions.UPDATE_NOTE_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isSubmittingNote: false,
      isAddedNote: false,
      error: true,
    }),

    [OpportunityActions.EXPORT_CSV_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [OpportunityActions.EXPORT_CSV_OPPORTUNITY_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [OpportunityActions.EXPORT_CSV_OPPORTUNITY_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [OpportunityActions.EXPORT_VIEW_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isExported: true,
    }),
    [OpportunityActions.EXPORT_VIEW_OPPORTUNITY_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
    }),
    [OpportunityActions.EXPORT_VIEW_OPPORTUNITY_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isExported: false,
      error: true,
    }),

    [OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: true,
    }),
    [OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
    }),
    [OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isDownloaded: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FILTER_AGENCY_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingAgency: true,
    }),
    [OpportunityActions.FILTER_AGENCY_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingAgency: false,
      agencyList: action.payload.agencyList,
    }),
    [OpportunityActions.FILTER_AGENCY_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingAgency: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FILTER_PSC_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPsc: true,
    }),
    [OpportunityActions.FILTER_PSC_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPsc: false,
      pscList: action.payload.pscList,
    }),
    [OpportunityActions.FILTER_PSC_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPsc: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FILTER_NAICS_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNaisc: true,
    }),
    [OpportunityActions.FILTER_NAICS_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNaisc: false,
      naiscList: action.payload.naiscList,
    }),
    [OpportunityActions.FILTER_NAICS_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingNaisc: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FILTER_PERSON_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPerson: true,
    }),
    [OpportunityActions.FILTER_PERSON_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPerson: false,
      personList: action.payload.personList,
    }),
    [OpportunityActions.FILTER_PERSON_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingPerson: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_FILTER_VENDOR_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingVendor: true,
    }),
    [OpportunityActions.FILTER_VENDOR_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingVendor: false,
      vendorList: action.payload.vendorList,
    }),
    [OpportunityActions.FILTER_VENDOR_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingVendor: false,
      error: true,
    }),

    [OpportunityActions.REQUEST_OPPORTUNITY_STACK_LIST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingStack: true,
      opportunity: [],
    }),
    [OpportunityActions.OPPORTUNITY_STACK_LIST_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingStack: false,
      opportunityStackData: action.payload.opportunityStackData,
    }),
    [OpportunityActions.OPPORTUNITY_STACK_LIST_FAILURE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoadingStack: false,
      error: true,
    }),
    [OpportunityActions.OPPORTUNITY_LIKE_UNLIKE_SUCCESS]: (
      state = OpportunityInitialStates,
      action
    ) => {
      let opportunity = state.opportunity;
      let opportunityData = state.opportunityData;
      let opportunityIndex = opportunity.findIndex(x => x._id === action.payload.opportunityId);
      if (opportunityIndex !== -1) {
        opportunity[opportunityIndex].isFollowed = action.payload.status;
      }
      if (Object.keys(opportunityData).length !== 0) {
        if (opportunityData._id.toString() === action.payload.opportunityId.toString()) {
          opportunityData.isFollowed = action.payload.status;
        }
      }
      return ({
        ...state,
        opportunity,
        opportunityData,
        isFollowedLoading: false,
      })
    },
    [OpportunityActions.FOLLOW_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isFollowedLoading: true,
    }),
    [OpportunityActions.UNFOLLOW_OPPORTUNITY_REQUEST]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isFollowedLoading: true,
    }),
    [OpportunityActions.RESET_OPPORTUNITY_VALUE]: (
      state = OpportunityInitialStates,
      action
    ) => ({
      ...state,
      isLoading: true,
      opportunity: [],
      opportunityData: {},
    }),
  },
  OpportunityInitialStates
);
