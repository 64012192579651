/** @format */

import { createLogic } from "redux-logic";
import {
  exportFollowedOpportunityFailed,
  exportFollowedOpportunitySuccess,
  addNoteFailed,
  addNoteSuccess,
  addToPipelineFailed,
  addToPipelineSuccess,
  deleteNoteFailed,
  exportOpportunitySuccess,
  exportOpportunityFailed,
  exportViewOpportunitySuccess,
  exportViewOpportunityFailed,
  followedOpportunityListFailed,
  followedOpportunityListSuccess,
  noteListFailed,
  noteListSuccess,
  OpportunityActions,
  opportunityListFailed,
  opportunityListSuccess,
  requestNoteList,
  // requestOpportunityList,
  updateNoteFailed,
  updateNoteSuccess,
  viewOpportunityFailed,
  viewOpportunitySuccess,
  requestFollowedOpportunityList,
  filterNaicsListSuccess,
  filterNaicsListFailed,
  filterPscListSuccess,
  filterPscListFailed,
  filterAgencyListSuccess,
  filterAgencyListFailed,
  filterPersonListFailed,
  filterPersonListSuccess,
  filterVendorListFailed,
  filterVendorListSuccess,
  opportunityStackListSuccess,
  opportunityStackListFailed,
  // viewOpportunityRequest,
  opportunityLikeUnlikeSuccess,
  getUserDetails,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getOpportunityLogic = createLogic({
  type: OpportunityActions.REQUEST_OPPORTUNITY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_OPPORTUNITY.service,
      ApiRoutes.GET_OPPORTUNITY.url,
      ApiRoutes.GET_OPPORTUNITY.method,
      ApiRoutes.GET_OPPORTUNITY.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(opportunityListFailed());
      done();
    } else {
      dispatch(
        opportunityListSuccess({
          opportunity: responseData.data,
          isEmpty: responseData.isEmpty,
          totalRecords: responseData.totalRecords,
          opportunitySetAside: responseData.opportunitySetAside,
          opportunityType: responseData.opportunityType,
          opportunityStack: responseData.opportunityStack,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFollowedOpportunityLogic = createLogic({
  type: OpportunityActions.REQUEST_FOLLOWED_OPPORTUNITY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FOLLOWED_OPPORTUNITY.service,
      ApiRoutes.GET_FOLLOWED_OPPORTUNITY.url,
      ApiRoutes.GET_FOLLOWED_OPPORTUNITY.method,
      ApiRoutes.GET_FOLLOWED_OPPORTUNITY.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(followedOpportunityListFailed());
      done();
    } else {
      dispatch(
        followedOpportunityListSuccess({
          followedOpportunity: responseData.data,
          isEmpty: responseData.isEmpty,
          totalFollowedRecords: responseData.totalRecords,
          followedOpportunityStack: responseData.followedOpportunityStack,
          opportunityType: responseData.opportunityType,
          opportunityStack: responseData.opportunityStack,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const viewOpportunityLogic = createLogic({
  type: OpportunityActions.VIEW_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_OPPORTUNITY.service,
      ApiRoutes.VIEW_OPPORTUNITY.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_OPPORTUNITY.method,
      ApiRoutes.VIEW_OPPORTUNITY.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(viewOpportunityFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewOpportunitySuccess({
          opportunityData: responseData.data,
        })
      );
      done();
    }
  },
});

const addToPiplelineLogic = createLogic({
  type: OpportunityActions.REQUEST_ADD_TO_PIPELINE,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_TO_PIPELINE.service,
      ApiRoutes.ADD_TO_PIPELINE.url,
      ApiRoutes.ADD_TO_PIPELINE.method,
      ApiRoutes.ADD_TO_PIPELINE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addToPipelineSuccess());
      // dispatch(requestOpportunityList());
      showSuccess(messages[0]);
      done();
    } else {
      showError(messages[0]);
      dispatch(addToPipelineFailed());
      done();
    }
  },
});

/**
 *
 */
const followOpportunityLogic = createLogic({
  type: OpportunityActions.FOLLOW_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.FOLLOW_OPPORTUNITY.service,
      ApiRoutes.FOLLOW_OPPORTUNITY.url,
      ApiRoutes.FOLLOW_OPPORTUNITY.method,
      ApiRoutes.FOLLOW_OPPORTUNITY.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      showError(messages[0]);

      done();
    } else {
      showSuccess(messages[0]);
      dispatch(opportunityLikeUnlikeSuccess({ opportunityId: action.payload.opportunityId, status: true }));
      dispatch(getUserDetails());
      dispatch(requestFollowedOpportunityList());
      // dispatch(requestOpportunityList());

      /* dispatch(
        viewOpportunityRequest({
          id: action.payload.opportunityId,
        })
      ); */
      done();
    }
  },
});

/**
 *
 */
const unfollowOpportunityLogic = createLogic({
  type: OpportunityActions.UNFOLLOW_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UNFOLLOW_OPPORTUNITY.service,
      ApiRoutes.UNFOLLOW_OPPORTUNITY.url.replace(
        ":id",
        action.payload.opportunityId
      ),

      ApiRoutes.UNFOLLOW_OPPORTUNITY.method,
      ApiRoutes.UNFOLLOW_OPPORTUNITY.authenticate,
      undefined,
      action.payload
    );
    if (isError) {
      showError(messages[0]);

      done();
    } else {
      showSuccess(messages[0]);
      dispatch(opportunityLikeUnlikeSuccess({ opportunityId: action.payload.opportunityId, status: false }));
      dispatch(getUserDetails());
      dispatch(requestFollowedOpportunityList());
      // dispatch(requestOpportunityList());
      /* dispatch(
        viewOpportunityRequest({
          id: action.payload.opportunityId,
        })
      ); */

      done();
    }
  },
});

/**
 *
 */
const addNoteLogic = createLogic({
  type: OpportunityActions.REQUEST_ADD_NOTE,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_NOTE.service,
      ApiRoutes.ADD_NOTE.url,
      ApiRoutes.ADD_NOTE.method,
      ApiRoutes.ADD_NOTE.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(addNoteSuccess());
      showSuccess(messages[0]);
      dispatch(requestNoteList({ id: action.payload.opportunityId }));
      done();
    } else {
      showError(messages[0]);
      dispatch(addNoteFailed());
      done();
    }
  },
});

/**
 *
 */
const getNotesLogic = createLogic({
  type: OpportunityActions.REQUEST_NOTE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, data: responseData } =
      await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_NOTE.service,
        ApiRoutes.GET_NOTE.url.replace(":id", action.payload.id),
        ApiRoutes.GET_NOTE.method,
        ApiRoutes.GET_NOTE.authenticate,
        undefined,
        undefined
      );
    if (isError) {
      // showError(messages[0]);
      dispatch(noteListFailed());
      done();
    } else {
      dispatch(
        noteListSuccess({
          notes: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const updateNoteLogic = createLogic({
  type: OpportunityActions.UPDATE_NOTE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.UPDATE_NOTE.service,
      ApiRoutes.UPDATE_NOTE.url.replace(":id", action.payload.id),
      ApiRoutes.UPDATE_NOTE.method,
      ApiRoutes.UPDATE_NOTE.authenticate,
      undefined,
      action.payload.values
    );
    if (!isError) {
      dispatch(updateNoteSuccess());
      showSuccess(messages[0]);
      dispatch(requestNoteList({ id: action.payload.opportunityId }));
      done();
    } else {
      showError(messages[0]);
      dispatch(updateNoteFailed());
      done();
    }
  },
});

/**
 *
 */
const deleteNoteLogic = createLogic({
  type: OpportunityActions.DELETE_NOTE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_NOTE.service,
      ApiRoutes.DELETE_NOTE.url.replace(":id", action.payload.id),
      ApiRoutes.DELETE_NOTE.method,
      ApiRoutes.DELETE_NOTE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(deleteNoteFailed(messages[0]));
      done();
    } else {
      dispatch(messages[0]);
      showSuccess(messages[0]);
      dispatch(requestNoteList({ id: action.payload.opportunityId }));
      done();
    }
  },
});

/**
 *
 */
const exportOpportunityLogic = createLogic({
  type: OpportunityActions.EXPORT_CSV_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_OPPORTUNITY.service,
      ApiRoutes.EXPORT_CSV_OPPORTUNITY.url,
      ApiRoutes.EXPORT_CSV_OPPORTUNITY.method,
      ApiRoutes.EXPORT_CSV_OPPORTUNITY.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportOpportunityFailed(messages[0]));
      done();
    } else {
      dispatch(exportOpportunitySuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */
const exportViewOpportunityLogic = createLogic({
  type: OpportunityActions.EXPORT_VIEW_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_OPPORTUNITY.service,
      ApiRoutes.EXPORT_VIEW_OPPORTUNITY.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_VIEW_OPPORTUNITY.method,
      ApiRoutes.EXPORT_VIEW_OPPORTUNITY.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewOpportunityFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewOpportunitySuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

const exportFollowedOpportunityLogic = createLogic({
  type: OpportunityActions.EXPORT_CSV_FOLLOWED_OPPORTUNITY_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_FOLLOWED_CSV_OPPORTUNITY.service,
      ApiRoutes.EXPORT_FOLLOWED_CSV_OPPORTUNITY.url,
      ApiRoutes.EXPORT_FOLLOWED_CSV_OPPORTUNITY.method,
      ApiRoutes.EXPORT_FOLLOWED_CSV_OPPORTUNITY.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportFollowedOpportunityFailed(messages[0]));
      done();
    } else {
      dispatch(exportFollowedOpportunitySuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const getFilterAgencyLogic = createLogic({
  type: OpportunityActions.REQUEST_FILTER_AGENCY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_AGENCY_LIST.service,
      ApiRoutes.GET_AGENCY_LIST.url,
      ApiRoutes.GET_AGENCY_LIST.method,
      ApiRoutes.GET_AGENCY_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(filterAgencyListFailed());
      done();
    } else {
      dispatch(
        filterAgencyListSuccess({
          agencyList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterPscLogic = createLogic({
  type: OpportunityActions.REQUEST_FILTER_PSC_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PSC_LIST.service,
      ApiRoutes.GET_PSC_LIST.url,
      ApiRoutes.GET_PSC_LIST.method,
      ApiRoutes.GET_PSC_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(filterPscListFailed());
      done();
    } else {
      dispatch(
        filterPscListSuccess({
          pscList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterNaicsLogic = createLogic({
  type: OpportunityActions.REQUEST_FILTER_NAICS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_NAICS_LIST.service,
      ApiRoutes.GET_NAICS_LIST.url,
      ApiRoutes.GET_NAICS_LIST.method,
      ApiRoutes.GET_NAICS_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(filterNaicsListFailed());
      done();
    } else {
      dispatch(
        filterNaicsListSuccess({
          naiscList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterPersonLogic = createLogic({
  type: OpportunityActions.REQUEST_FILTER_PERSON_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PERSON_LIST.service,
      ApiRoutes.GET_PERSON_LIST.url,
      ApiRoutes.GET_PERSON_LIST.method,
      ApiRoutes.GET_PERSON_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(filterPersonListFailed());
      done();
    } else {
      dispatch(
        filterPersonListSuccess({
          personList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterVendorLogic = createLogic({
  type: OpportunityActions.REQUEST_FILTER_VENDOR_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_VENDOR_LIST.service,
      ApiRoutes.GET_VENDOR_LIST.url,
      ApiRoutes.GET_VENDOR_LIST.method,
      ApiRoutes.GET_VENDOR_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(filterVendorListFailed());
      done();
    } else {
      dispatch(
        filterVendorListSuccess({
          vendorList: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getOpportunityStackLogic = createLogic({
  type: OpportunityActions.REQUEST_OPPORTUNITY_STACK_LIST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_OPPORTUNITY_STACK.service,
      ApiRoutes.GET_OPPORTUNITY_STACK.url.replace(":id", action.payload.id),
      ApiRoutes.GET_OPPORTUNITY_STACK.method,
      ApiRoutes.GET_OPPORTUNITY_STACK.authenticate,
      undefined,
      undefined
    );

    if (isError) {
      dispatch(opportunityStackListFailed(messages[0]));
      done();
    } else {
      dispatch(
        opportunityStackListSuccess({
          opportunityStackData: responseData.data,
        })
      );
      done();
    }
  },
});

export const OpportunityLogics = [
  getOpportunityLogic,
  viewOpportunityLogic,
  addToPiplelineLogic,
  addNoteLogic,
  getNotesLogic,
  deleteNoteLogic,
  updateNoteLogic,
  followOpportunityLogic,
  unfollowOpportunityLogic,
  exportOpportunityLogic,
  getFollowedOpportunityLogic,
  getFilterAgencyLogic,
  getFilterPscLogic,
  getFilterNaicsLogic,
  getFilterPersonLogic,
  getFilterVendorLogic,
  exportFollowedOpportunityLogic,
  exportViewOpportunityLogic,
  getOpportunityStackLogic,
];
