/** @format */

import { createAction } from 'redux-actions'

export * from './Auth'
export * from './Modal'
export * from './Dashboard'
export * from './Pipeline'
export * from './Stage'
export * from './Opportunity'
export * from './Awards'
export * from './SavedSearch'
export * from './Psc'
export * from './Naics'
export * from './FederalAgencies'
export * from './FederalPeople'
export * from './Vendor'
export * from './GlobalSearch'
export * from './Notification'

export const redirectTo = createAction('REDIRECT_TO')
