/** @format */

import { createLogic } from "redux-logic";
import {
  exportFederalPeopleSuccess,
  exportFederalPeopleFailed,
  exportViewFederalPeopleSuccess,
  exportViewFederalPeopleFailed,
  FederalPeopleActions,
  federalpeopleListFailed,
  federalpeopleListSuccess,
  viewFederalPeopleFailed,
  viewFederalPeopleSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getFederalPeopleLogic = createLogic({
  type: FederalPeopleActions.REQUEST_FEDERALPEOPLE_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FEDERAL_PEOPLE.service,
      ApiRoutes.GET_FEDERAL_PEOPLE.url,
      ApiRoutes.GET_FEDERAL_PEOPLE.method,
      ApiRoutes.GET_FEDERAL_PEOPLE.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(federalpeopleListFailed());
      done();
    } else {
      dispatch(
        federalpeopleListSuccess({
          federalpeople: responseData.data,
          isEmpty: responseData.isEmpty,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */

const viewFederalPeopleLogic = createLogic({
  type: FederalPeopleActions.VIEW_FEDERALPEOPLE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_FEDERALPEOPLE.service,
      ApiRoutes.VIEW_FEDERALPEOPLE.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_FEDERALPEOPLE.method,
      ApiRoutes.VIEW_FEDERALPEOPLE.authenticate,
      undefined,

      undefined
    );

    if (isError) {
      dispatch(viewFederalPeopleFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewFederalPeopleSuccess({
          federalpeopleData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const exportFederalPeopleLogic = createLogic({
  type: FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_FEDERALPEOPLE.service,
      ApiRoutes.EXPORT_CSV_FEDERALPEOPLE.url,
      ApiRoutes.EXPORT_CSV_FEDERALPEOPLE.method,
      ApiRoutes.EXPORT_CSV_FEDERALPEOPLE.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportFederalPeopleFailed(messages[0]));
      done();
    } else {
      dispatch(exportFederalPeopleSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const exportViewFederalPeopleLogic = createLogic({
  type: FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_FEDERALPEOPLE.service,
      ApiRoutes.EXPORT_VIEW_FEDERALPEOPLE.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_VIEW_FEDERALPEOPLE.method,
      ApiRoutes.EXPORT_VIEW_FEDERALPEOPLE.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewFederalPeopleFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewFederalPeopleSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

export const FederalPeopleLogics = [
  getFederalPeopleLogic,
  viewFederalPeopleLogic,
  exportFederalPeopleLogic,
  exportViewFederalPeopleLogic,
];
