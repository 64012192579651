/** @format */

export const PscInitialStates = {
  isLoading: false,
  psc: [],
  pscData: {},
  isDownloaded: false,

  isPscHierarchyLoading: false,
  pscHierarchy: [],

  isfundingLoading: false,
  fundingData: [],
  totalDollarsObligated: "",
  fundingGraphData: [],
};
