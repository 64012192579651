import { createAction } from "redux-actions";

export const NotificationActions = {
  REQUEST_NOTIFICATION_LIST: "Request to fetch Notification list",
  NOTIFICATION_LIST_SUCCESS: "Notification list fetched successfully",
  NOTIFICATION_LIST_FAILURE: "Failed to fetched Notification list",

  UPDATE_NOTIFICATION_STATUS_REQUEST: "Request to update notification status",
  UPDATE_NOTIFICATION_STATUS_SUCCESS:
    "Notification status updated successfully",
  UPDATE_NOTIFICATION_STATUS_FAILURE: "Failed to update notification status",

  MARK_ALL_READ_REQUEST: "Request to mark all rea notification status",
  MARK_ALL_READ_SUCCESS: "Notification status mark all read successfully",
  MARK_ALL_READ_FAILURE: "Failed to mark all rea notification status",
};

// Notification List Actions

export const requestNotificationList = createAction(
  NotificationActions.REQUEST_NOTIFICATION_LIST
);
export const notificationListSuccess = createAction(
  NotificationActions.NOTIFICATION_LIST_SUCCESS
);
export const notificationListFailed = createAction(
  NotificationActions.NOTIFICATION_LIST_FAILURE
);

export const updateNotificationStatusRequest = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_REQUEST
);
export const updateNotificationStatusSuccess = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_SUCCESS
);
export const updateNotificationStatusFailed = createAction(
  NotificationActions.UPDATE_NOTIFICATION_STATUS_FAILURE
);

export const makrAllReadRequest = createAction(
  NotificationActions.MARK_ALL_READ_REQUEST
);
export const makrAllReadSuccess = createAction(
  NotificationActions.MARK_ALL_READ_SUCCESS
);
export const makrAllReadFailed = createAction(
  NotificationActions.MARK_ALL_READ_FAILURE
);
