import { createAction } from "redux-actions";

export const NaicsActions = {
  REQUEST_NAICS_LIST: "Request to fetch Naics list",
  NAICS_LIST_SUCCESS: "Naics list fetched successfully",
  NAICS_LIST_FAILURE: "Failed to fetched Naics list",

  VIEW_NAICS_REQUEST: "Request to view Naics",
  VIEW_NAICS_SUCCESS: "Naics details fetched successfully",
  VIEW_NAICS_FAILURE: "Failed to fetch naics details",

  EXPORT_CSV_NAICS_REQUEST: "Request to export csv naics",
  EXPORT_CSV_NAICS_SUCCESS: "naics export successfully",
  EXPORT_CSV_NAICS_FAILURE: "naics export Failed",

  EXPORT_VIEW_NAICS_REQUEST: "Request to export view naics",
  EXPORT_VIEW_NAICS_SUCCESS: "naics export view successfully",
  EXPORT_VIEW_NAICS_FAILURE: "naics export view Failed",


  REQUEST_NAICS_HIERARCHY_LIST: "Request to fetch Naics Hierarchy list",
  NAICS_HIERARCHY_LIST_SUCCESS: "Naics Hierarchy list fetched successfully",
  NAICS_HIERARCHY_LIST_FAILURE: "Failed to fetched Naics Hierarchy list",

  REQUEST_FILTER_PARENT_NAICS_LIST: "Request to fetch filter parent naics list",
  FILTER_PARENT_NAICS_LIST_SUCCESS:
    "Filter parent naics  list fetched successfully",
  FILTER_PARENT_NAICS_LIST_FAILURE:
    "Failed to fetched filter parent naics  list",

  REQUEST_NAICS_FUNDING_ANALYSIS_LIST:
    "Request to fetch Naics funding Analysis list",
  NAICS_FUNDING_ANALYSIS_LIST_SUCCESS:
    "Naics funding Analysis list fetched successfully",
  NAICS_FUNDING_ANALYSIS_LIST_FAILURE:
    "Failed to fetched Naics funding Analysis list",
};

// List opportunity actions
export const requestNaicsList = createAction(NaicsActions.REQUEST_NAICS_LIST);
export const naicsListSuccess = createAction(NaicsActions.NAICS_LIST_SUCCESS);
export const naicsListFailed = createAction(NaicsActions.NAICS_LIST_FAILURE);

// View opportunity actions
export const viewNaicsRequest = createAction(NaicsActions.VIEW_NAICS_REQUEST);
export const viewNaicsSuccess = createAction(NaicsActions.VIEW_NAICS_SUCCESS);
export const viewNaicsFailed = createAction(NaicsActions.VIEW_NAICS_FAILURE);

export const exportNaicsRequest = createAction(
  NaicsActions.EXPORT_CSV_NAICS_REQUEST
);
export const exportNaicsSuccess = createAction(
  NaicsActions.EXPORT_CSV_NAICS_SUCCESS
);
export const exportNaicsFailed = createAction(
  NaicsActions.EXPORT_CSV_NAICS_FAILURE
);


export const exportViewNaicsRequest = createAction(
  NaicsActions.EXPORT_VIEW_NAICS_REQUEST
);
export const exportViewNaicsSuccess = createAction(
  NaicsActions.EXPORT_VIEW_NAICS_SUCCESS
);
export const exportViewNaicsFailed = createAction(
  NaicsActions.EXPORT_VIEW_NAICS_FAILURE
);

// List Naics Hierarchy List actions
export const requestNaicsHierarchyList = createAction(
  NaicsActions.REQUEST_NAICS_HIERARCHY_LIST
);
export const naicsHierarchyListSuccess = createAction(
  NaicsActions.NAICS_HIERARCHY_LIST_SUCCESS
);
export const naicsHierarchyListFailed = createAction(
  NaicsActions.NAICS_HIERARCHY_LIST_FAILURE
);

// List opportunity actions
export const requestParentNaicsList = createAction(
  NaicsActions.REQUEST_FILTER_PARENT_NAICS_LIST
);
export const parentNaicsListSuccess = createAction(
  NaicsActions.FILTER_PARENT_NAICS_LIST_SUCCESS
);
export const parentNaicsListFailed = createAction(
  NaicsActions.FILTER_PARENT_NAICS_LIST_FAILURE
);

// List Naics funding analysis actions
export const requestNaicsFundingAnalysisList = createAction(
  NaicsActions.REQUEST_NAICS_FUNDING_ANALYSIS_LIST
);
export const naicsFundingAnalysisListSuccess = createAction(
  NaicsActions.NAICS_FUNDING_ANALYSIS_LIST_SUCCESS
);
export const naicsFundingAnalysisListFailed = createAction(
  NaicsActions.NAICS_FUNDING_ANALYSIS_LIST_FAILURE
);
