/** @format */

import { createLogic } from "redux-logic";
import {
  GlobalSearchActions,
  globalSearchSuccess,
  globalSearchFailed,
} from "../actions";
import { showError } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const GlobalSearchLogic = createLogic({
  type: GlobalSearchActions.GLOBAL_SEARCH_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GLOBAL_SEARH.service,
      ApiRoutes.GLOBAL_SEARH.url,
      ApiRoutes.GLOBAL_SEARH.method,
      ApiRoutes.GLOBAL_SEARH.authenticate,
      undefined,
      action.payload
    );
    if (!isError) {
      dispatch(
        globalSearchSuccess({
          globalSearchData: responseData.data,
        })
      );

      done();
    } else {
      showError(messages[0]);
      dispatch(globalSearchFailed());
      done();
    }
  },
});

export const GlobalSearchLogics = [GlobalSearchLogic];
