import { createAction } from "redux-actions";

export const FederalPeopleActions = {
  REQUEST_FEDERALPEOPLE_LIST: "Request to fetch FederalPeople list",
  FEDERALPEOPLE_LIST_SUCCESS: "FederalPeople list fetched successfully",
  FEDERALPEOPLE_LIST_FAILURE: "Failed to fetched FederalPeople list",

  VIEW_FEDERALPEOPLE_REQUEST: "Request to view FederalPeople",
  VIEW_FEDERALPEOPLE_SUCCESS: "FederalPeople details fetched successfully",
  VIEW_FEDERALPEOPLE_FAILURE: "Failed to fetch federalpeople details",
  
  EXPORT_CSV_FEDERALPEOPLE_REQUEST: "Request to export csv federalpeople",
  EXPORT_CSV_FEDERALPEOPLE_SUCCESS: "federalpeople export successfully",
  EXPORT_CSV_FEDERALPEOPLE_FAILURE: "federalpeople export Failed",

  EXPORT_VIEW_FEDERALPEOPLE_REQUEST: "Request to export view federalpeople",
  EXPORT_VIEW_FEDERALPEOPLE_SUCCESS: "federalpeople export view successfully",
  EXPORT_VIEW_FEDERALPEOPLE_FAILURE: "federalpeople export view Failed",
};

// List opportunity actions
export const requestFederalPeopleList = createAction(
    FederalPeopleActions.REQUEST_FEDERALPEOPLE_LIST
);
export const federalpeopleListSuccess = createAction(
    FederalPeopleActions.FEDERALPEOPLE_LIST_SUCCESS
);
export const federalpeopleListFailed = createAction(
    FederalPeopleActions.FEDERALPEOPLE_LIST_FAILURE
);

// View opportunity actions
export const viewFederalPeopleRequest = createAction(
    FederalPeopleActions.VIEW_FEDERALPEOPLE_REQUEST
);
export const viewFederalPeopleSuccess = createAction(
    FederalPeopleActions.VIEW_FEDERALPEOPLE_SUCCESS
);
export const viewFederalPeopleFailed = createAction(
    FederalPeopleActions.VIEW_FEDERALPEOPLE_FAILURE
);


export const exportFederalPeopleRequest = createAction(
    FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_REQUEST
);
export const exportFederalPeopleSuccess = createAction(
    FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_SUCCESS
);
export const exportFederalPeopleFailed = createAction(
    FederalPeopleActions.EXPORT_CSV_FEDERALPEOPLE_FAILURE
);

export const exportViewFederalPeopleRequest = createAction(
    FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_REQUEST
);
export const exportViewFederalPeopleSuccess = createAction(
    FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_SUCCESS
);
export const exportViewFederalPeopleFailed = createAction(
    FederalPeopleActions.EXPORT_VIEW_FEDERALPEOPLE_FAILURE
);
