/** @format */

export const FederalPeopleInitialStates = {
    isLoading: false,
    federalpeople: [],
    federalpeopleData: {},
    isDownloaded: false,

    viewFederalPeople: [],
    isExported: false,
  };
  