import { createAction } from "redux-actions";

export const DashboardActions = {
  REQUEST_OPPORTUNITIES_DUE_LIST: "Request to fetch Opportunities Due list",
  OPPORTUNITIES_DUE_LIST_SUCCESS: "Opportunities Due list fetched successfully",
  OPPORTUNITIES_DUE_LIST_FAILURE: "Failed to fetched Opportunities Due list",

  REQUEST_PIPELINE_OVERVIEW_LIST: "Request to fetch Pipeline_overview list",
  PIPELINE_OVERVIEW_LIST_SUCCESS: "Pipeline_overview list fetched successfully",
  PIPELINE_OVERVIEW_LIST_FAILURE: "Failed to fetched Pipeline_overview list",

  REQUEST_CARD_COUNT: "Request to fetch card count",
  CARD_COUNT_SUCCESS: "card count fetched successfully",
  CARD_COUNT_FAILURE: "Failed to fetched card count",
};

// List OPPORTUNITIES_DUE actions
export const requestOpportunitiesDueList = createAction(
  DashboardActions.REQUEST_OPPORTUNITIES_DUE_LIST
);
export const opportunitiesDueListSuccess = createAction(
  DashboardActions.OPPORTUNITIES_DUE_LIST_SUCCESS
);
export const opportunitiesDueListFailed = createAction(
  DashboardActions.OPPORTUNITIES_DUE_LIST_FAILURE
);

// List PIPELINE_OVERVIEW actions
export const requestPipelineOverviewList = createAction(
  DashboardActions.REQUEST_PIPELINE_OVERVIEW_LIST
);
export const pipelineOverviewListSuccess = createAction(
  DashboardActions.PIPELINE_OVERVIEW_LIST_SUCCESS
);
export const pipelineOverviewListFailed = createAction(
  DashboardActions.PIPELINE_OVERVIEW_LIST_FAILURE
);

// card count actions
export const requestCardCount = createAction(
  DashboardActions.REQUEST_CARD_COUNT
);
export const cardCountSuccess = createAction(
  DashboardActions.CARD_COUNT_SUCCESS
);
export const cardCountFailed = createAction(
  DashboardActions.CARD_COUNT_FAILURE
);
