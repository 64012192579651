/** @format */

import { createLogic } from "redux-logic";
import {
  exportPscSuccess,
  exportPscFailed,
  exportViewPscSuccess,
  exportViewPscFailed,
  PscActions,
  pscFundingAnalysisListFailed,
  pscFundingAnalysisListSuccess,
  pscHierarchyListFailed,
  pscHierarchyListSuccess,
  pscListFailed,
  pscListSuccess,
  viewPscFailed,
  viewPscSuccess,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getPscLogic = createLogic({
  type: PscActions.REQUEST_PSC_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PSC.service,
      ApiRoutes.GET_PSC.url,
      ApiRoutes.GET_PSC.method,
      ApiRoutes.GET_PSC.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(pscListFailed());
      done();
    } else {
      dispatch(
        pscListSuccess({
          psc: responseData.data,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */

const viewPscLogic = createLogic({
  type: PscActions.VIEW_PSC_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_PSC.service,
      ApiRoutes.VIEW_PSC.url.replace(":id", action.payload.id),
      ApiRoutes.VIEW_PSC.method,
      ApiRoutes.VIEW_PSC.authenticate,
      { awardId: action.payload.awardId },
      undefined
    );

    if (isError) {
      dispatch(viewPscFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewPscSuccess({
          pscData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const exportPscLogic = createLogic({
  type: PscActions.EXPORT_CSV_PSC_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_PSC.service,
      ApiRoutes.EXPORT_CSV_PSC.url,
      ApiRoutes.EXPORT_CSV_PSC.method,
      ApiRoutes.EXPORT_CSV_PSC.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportPscFailed(messages[0]));
      done();
    } else {
      dispatch(exportPscSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

const exportViewPscLogic = createLogic({
  type: PscActions.EXPORT_VIEW_PSC_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_PSC.service,
      ApiRoutes.EXPORT_VIEW_PSC.url.replace(":id", action.payload.id),
      ApiRoutes.EXPORT_VIEW_PSC.method,
      ApiRoutes.EXPORT_VIEW_PSC.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewPscFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewPscSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

const getPscHierarchyLogic = createLogic({
  type: PscActions.REQUEST_PSC_HIERARCHY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PSC_HIERARCHY.service,
      ApiRoutes.GET_PSC_HIERARCHY.url,
      ApiRoutes.GET_PSC_HIERARCHY.method,
      ApiRoutes.GET_PSC_HIERARCHY.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(pscHierarchyListFailed());
      done();
    } else {
      dispatch(
        pscHierarchyListSuccess({
          pscHierarchy: responseData.data,
        })
      );
      done();
    }
  },
});

const getPscFundingAnalysisLogic = createLogic({
  type: PscActions.REQUEST_PSC_FUNDING_ANALYSIS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PSC_FUNDING_ANALYSIS.service,
      ApiRoutes.GET_PSC_FUNDING_ANALYSIS.url,
      ApiRoutes.GET_PSC_FUNDING_ANALYSIS.method,
      ApiRoutes.GET_PSC_FUNDING_ANALYSIS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(pscFundingAnalysisListFailed());
      done();
    } else {
      dispatch(
        pscFundingAnalysisListSuccess({
          fundingData: responseData.data,
          fundingGraphData: responseData.graphData,
          totalDollarsObligated: responseData.totalDollarsObligated,
        })
      );
      done();
    }
  },
});

export const PscLogics = [
  getPscLogic,
  viewPscLogic,
  exportPscLogic,
  exportViewPscLogic,
  getPscHierarchyLogic,
  getPscFundingAnalysisLogic,
];
