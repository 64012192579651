/** @format */

import { createLogic } from "redux-logic";
import {
  exportFederalAgenciesSuccess,
  exportFederalAgenciesFailed,
  exportViewFederalAgenciesSuccess,
  exportViewFederalAgenciesFailed,
  FederalAgenciesActions,
  federalagenciesListFailed,
  federalagenciesListSuccess,
  viewFederalAgenciesFailed,
  viewFederalAgenciesSuccess,
  federalHierarchyFailed,
  federalHierarchySuccess,
  parentAgencyListFailed,
  parentAgencyListSuccess,
  agencyFundingAnalysisListSuccess,
  agencyFundingAnalysisListFailed,
} from "../actions";
import { showError, showSuccess } from "../../helpers/Toast";
import ApiRoutes from "../../config/ApiRoutes";
import { ApiHelper } from "../../helpers";

/**
 *
 */
const getFederalAgenciesLogic = createLogic({
  type: FederalAgenciesActions.REQUEST_FEDERALAGENCIES_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FEDERALAGENCIES.service,
      ApiRoutes.GET_FEDERALAGENCIES.url,
      ApiRoutes.GET_FEDERALAGENCIES.method,
      ApiRoutes.GET_FEDERALAGENCIES.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(federalagenciesListFailed());
      done();
    } else {
      dispatch(
        federalagenciesListSuccess({
          federalagencies: responseData.data,
          isEmpty: responseData.isEmpty,
          totalRecords: responseData.totalRecords,
        })
      );
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */

const viewFederalAgenciesLogic = createLogic({
  type: FederalAgenciesActions.VIEW_FEDERALAGENCIES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;

    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.VIEW_FEDERALAGENCIES_BY_NAME.service,
      ApiRoutes.VIEW_FEDERALAGENCIES_BY_NAME.url,
      ApiRoutes.VIEW_FEDERALAGENCIES_BY_NAME.method,
      ApiRoutes.VIEW_FEDERALAGENCIES_BY_NAME.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      dispatch(viewFederalAgenciesFailed(messages[0]));
      done();
    } else {
      dispatch(
        viewFederalAgenciesSuccess({
          federalagenciesData: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const exportFederalAgenciesLogic = createLogic({
  type: FederalAgenciesActions.EXPORT_CSV_FEDERALAGENCIES_REQUEST,
  async process(data, dispatch, done) {
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_CSV_FEDERALAGENCIES.service,
      ApiRoutes.EXPORT_CSV_FEDERALAGENCIES.url,
      ApiRoutes.EXPORT_CSV_FEDERALAGENCIES.method,
      ApiRoutes.EXPORT_CSV_FEDERALAGENCIES.authenticate,
      undefined,
      undefined
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportFederalAgenciesFailed(messages[0]));
      done();
    } else {
      dispatch(exportFederalAgenciesSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */

/**
 *
 */
const exportViewFederalAgenciesLogic = createLogic({
  type: FederalAgenciesActions.EXPORT_VIEW_FEDERALAGENCIES_REQUEST,
  async process(data, dispatch, done) {
    const { action } = data;
    const { isError, messages } = await new ApiHelper().FetchFromServer(
      ApiRoutes.EXPORT_VIEW_FEDERALAGENCIES.service,
      ApiRoutes.EXPORT_VIEW_FEDERALAGENCIES.url.replace(
        ":id",
        action.payload.id
      ),
      ApiRoutes.EXPORT_VIEW_FEDERALAGENCIES.method,
      ApiRoutes.EXPORT_VIEW_FEDERALAGENCIES.authenticate,
      undefined,
      { type: action.payload.type }
    );
    if (isError) {
      showError(messages[0]);
      dispatch(exportViewFederalAgenciesFailed(messages[0]));
      done();
    } else {
      dispatch(exportViewFederalAgenciesSuccess());
      showSuccess(messages[0]);
      done();
    }
  },
});

/**
 *
 */
const getFederalHierarchyLogic = createLogic({
  type: FederalAgenciesActions.REQUEST_FEDERAL_HIERARCHY,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_FEDERAL_HIERARCHY.service,
      ApiRoutes.GET_FEDERAL_HIERARCHY.url,
      ApiRoutes.GET_FEDERAL_HIERARCHY.method,
      ApiRoutes.GET_FEDERAL_HIERARCHY.authenticate,
      action.payload,
      undefined
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(federalHierarchyFailed());
      done();
    } else {
      dispatch(
        federalHierarchySuccess({
          federalHierarchy: responseData.data,
        })
      );
      done();
    }
  },
});

/**
 *
 */
const getFilterParentAgencyLogic = createLogic({
  type: FederalAgenciesActions.REQUEST_FILTER_PARENT_AGENCY_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,

      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_PARENT_AGENCY_LIST.service,
      ApiRoutes.GET_PARENT_AGENCY_LIST.url,
      ApiRoutes.GET_PARENT_AGENCY_LIST.method,
      ApiRoutes.GET_PARENT_AGENCY_LIST.authenticate,
      action.payload,
      undefined
    );
    if (isError) {
      dispatch(parentAgencyListFailed());
      done();
    } else {
      dispatch(
        parentAgencyListSuccess({
          parentAgencyList: responseData.data,
        })
      );
      done();
    }
  },
});

const getAgencyFundingAnalysisLogic = createLogic({
  type: FederalAgenciesActions.REQUEST_AGENCY_FUNDING_ANALYSIS_LIST,
  async process(data, dispatch, done) {
    const { action } = data;
    const {
      isError,
      messages,
      data: responseData,
    } = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_AGENCY_FUNDING_ANALYSIS.service,
      ApiRoutes.GET_AGENCY_FUNDING_ANALYSIS.url,
      ApiRoutes.GET_AGENCY_FUNDING_ANALYSIS.method,
      ApiRoutes.GET_AGENCY_FUNDING_ANALYSIS.authenticate,
      undefined,
      action.payload
    );

    if (isError) {
      if (messages[0]) {
        showError(messages[0]);
      }
      // showError(messages[0]);
      dispatch(agencyFundingAnalysisListFailed());
      done();
    } else {
      dispatch(
        agencyFundingAnalysisListSuccess({
          fundingData: responseData.data,
          fundingGraphData: responseData.graphData,
          totalDollarsObligated: responseData.totalDollarsObligated,
        })
      );
      done();
    }
  },
});

export const FederalAgenciesLogics = [
  getFederalAgenciesLogic,
  getAgencyFundingAnalysisLogic,
  viewFederalAgenciesLogic,
  exportFederalAgenciesLogic,
  exportViewFederalAgenciesLogic,
  getFederalHierarchyLogic,
  getFilterParentAgencyLogic,
];
