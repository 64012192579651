const ApiRoutes = {
  LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  SIGNUP: {
    service: "/auth",
    url: "/signup",
    method: "POST",
    authenticate: false,
  },
  VERIFY: {
    service: "/auth",
    url: "/verify/:id",
    method: "GET",
    authenticate: false,
  },
  FORGOT_PASSWORD: {
    service: "/auth",
    url: "/forget-password",
    method: "POST",
    authenticate: true,
  },
  SET_PASSWORD: {
    service: "/auth",
    url: "/set-password/:id",
    method: "PUT",
    authenticate: true,
  },

  VERIFY_LINK: {
    service: "/auth",
    url: "/verify-link",
    method: "POST",
    authenticate: true,
  },

  GET_PROFILE: {
    service: "/auth",
    url: "/me",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PROFILE: {
    service: "/auth",
    url: "/",
    method: "PUT",
    authenticate: true,
  },
  CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_NOTIFICATION: {
    service: "/auth",
    url: "/notification",
    method: "PUT",
    authenticate: true,
  },

  ADD_CARD: {
    service: "/card",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  GET_CARDS: {
    service: "/card",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  DELETE_CARD: {
    service: "/card",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_CARD_STATUS: {
    service: "/card",
    url: "/set-primary",
    method: "PUT",
    authenticate: true,
  },

  ADD_PIPELINE: {
    service: "/pipeline",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  GET_PIPELINES: {
    service: "/pipeline",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  VIEW_PIPELINE: {
    service: "/pipeline",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_PIPELINE: {
    service: "/pipeline",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_PIPELINE: {
    service: "/pipeline",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_STAGE: {
    service: "/pipeline",
    url: "/rename-stage/:id",
    method: "PUT",
    authenticate: true,
  },
  REORDER_OPPORTUNITY_STAGE: {
    service: "/opportunity",
    url: "/reorder",
    method: "POST",
    authenticate: true,
  },

  UPDATE_OPPORTUNITY_STAGE: {
    service: "/pipeline",
    url: "/change-opportunity-stage",
    method: "PUT",
    authenticate: true,
  },
  EXPORT_CSV_PIPELINE: {
    service: "/pipeline",
    url: "/export/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_STAGE_OPPORTUNITY: {
    service: "/opportunity",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_AWARDS: {
    service: "/awards",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  GET_FUNDING_AWARDS: {
    service: "/awards",
    url: "/funding-awards",
    method: "POST",
    authenticate: true,
  },
  VIEW_AWARDS: {
    service: "/awards",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_OPPORTUNITY: {
    service: "/opportunity",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  GET_PSC: {
    service: "/psc",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  VIEW_PSC: {
    service: "/psc",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EXPORT_CSV_PSC: {
    service: "/psc",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  GET_AGENCY_FUNDING_ANALYSIS: {
    service: "/federal-agency",
    url: "/funding-analysis",
    method: "POST",
    authenticate: true,
  },

  GET_PSC_HIERARCHY: {
    service: "/psc",
    url: "/psc-parent-hierarchy",
    method: "GET",
    authenticate: true,
  },

  GET_PSC_FUNDING_ANALYSIS: {
    service: "/psc",
    url: "/funding-analysis",
    method: "POST",
    authenticate: true,
  },

  GET_NAICS: {
    service: "/naics",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  VIEW_NAICS: {
    service: "/naics",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_NAICS_HIERARCHY: {
    service: "/naics",
    url: "/naics-parent-hierarchy",
    method: "GET",
    authenticate: true,
  },
  GET_NAICS_FUNDING_ANALYSIS: {
    service: "/naics",
    url: "/funding-analysis",
    method: "POST",
    authenticate: true,
  },

  GET_FEDERALAGENCIES: {
    service: "/federal-agency",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  GET_FEDERAL_HIERARCHY: {
    service: "/federal-agency",
    url: "/next-level-children",
    method: "GET",
    authenticate: true,
  },

  VIEW_FEDERALAGENCIES_BY_NAME: {
    service: "/federal-agency",
    url: "/by-name",
    method: "GET",
    authenticate: true,
  },
  VIEW_FEDERALAGENCIES: {
    service: "/federal-agency",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_FEDERAL_PEOPLE: {
    service: "/federal-person",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  VIEW_FEDERALPEOPLE: {
    service: "/federal-person",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_VENDOR: {
    service: "/vendors",
    url: "/get",
    method: "POST",
    authenticate: true,
  },
  VIEW_VENDOR: {
    service: "/vendors",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  LIKE_VENDOR: {
    service: "/vendors",
    url: "/followed",
    method: "POST",
    authenticate: true,
  },
  UNLIKE_VENDOR: {
    service: "/vendors",
    url: "/unfollowed/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_LIKED_VENDOR: {
    service: "/vendors",
    url: "/user",
    method: "POST",
    authenticate: true,
  },
  GET_VENDOR_FUNDING_ANALYSIS: {
    service: "/vendors",
    url: "/funding-analysis",
    method: "POST",
    authenticate: true,
  },
  GET_FOLLOWED_OPPORTUNITY: {
    service: "/opportunity",
    url: "/user/get",
    method: "POST",
    authenticate: true,
  },
  VIEW_OPPORTUNITY: {
    service: "/opportunity",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_OPPORTUNITY_STACK: {
    service: "/opportunity",
    url: "/stack/:id",
    method: "GET",
    authenticate: true,
  },

  ADD_TO_PIPELINE: {
    service: "/opportunity",
    url: "/add-to-pipeline",
    method: "POST",
    authenticate: true,
  },
  FOLLOW_OPPORTUNITY: {
    service: "/opportunity",
    url: "/followed",
    method: "POST",
    authenticate: true,
  },
  UNFOLLOW_OPPORTUNITY: {
    service: "/opportunity",
    url: "/unfollowed/:id",
    method: "DELETE",
    authenticate: true,
  },

  EXPORT_CSV_OPPORTUNITY: {
    service: "/opportunity",
    url: "/export",
    method: "GET",
    authenticate: true,
  },

  EXPORT_CSV_NAICS: {
    service: "/naics",
    url: "/export",
    method: "GET",
    authenticate: true,
  },

  INVITE_USER: {
    service: "/auth",
    url: "/invite-user",
    method: "POST",
    authenticate: true,
  },
  GET_INVITE_USER: {
    service: "/auth",
    url: "/child-user-list",
    method: "GET",
    authenticate: true,
  },
  REMOVE_USER: {
    service: "/auth",
    url: "/delete-user/:id",
    method: "PUT",
    authenticate: true,
  },
  RE_INVITE_USER: {
    service: "/auth",
    url: "/reinvite-user/:id",
    method: "GET",
    authenticate: true,
  },

  ADD_SAVED_SEARCH: {
    service: "/saved-search",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  GET_SAVED_SEARCH: {
    service: "/saved-search",
    url: "/list",
    method: "POST",
    authenticate: true,
  },
  UPDATE_SAVED_SEARCH: {
    service: "/saved-search",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_SAVED_SEARCH: {
    service: "/saved-search",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GLOBAL_SEARH: {
    service: "/explore",
    url: "/",
    method: "POST",
    authenticate: true,
  },

  ADD_NOTE: {
    service: "/opportunity-notes",
    url: "/",
    method: "POST",
    authenticate: true,
  },
  GET_NOTE: {
    service: "/opportunity-notes",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_NOTE: {
    service: "/opportunity-notes",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_NOTE: {
    service: "/opportunity-notes",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  ADD_SEARCH: {
    service: "/opportunity",
    url: "/search",
    method: "POST",
    authenticate: true,
  },
  GET_SEARCH: {
    service: "/opportunity",
    url: "/search/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SEARCH: {
    service: "/opportunity",
    url: "/search/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_SUBSCRIPTIONS: {
    service: "/auth",
    url: "/subscription",
    method: "GET",
    authenticate: true,
  },
  CANCEL_SUBSCRIPTION: {
    service: "/auth",
    url: "/cancel-subscription",
    method: "POST",
    authenticate: true,
  },

  GET_AGENCY_LIST: {
    service: "/federal-agency",
    url: "/filter",
    method: "GET",
    authenticate: true,
  },

  GET_PSC_LIST: {
    service: "/psc",
    url: "/filter",
    method: "GET",
    authenticate: true,
  },

  GET_NAICS_LIST: {
    service: "/naics",
    url: "/filter",
    method: "GET",
    authenticate: true,
  },

  GET_PERSON_LIST: {
    service: "/federal-person",
    url: "/filter",
    method: "GET",
    authenticate: true,
  },

  GET_VENDOR_LIST: {
    service: "/vendors",
    url: "/filter",
    method: "GET",
    authenticate: true,
  },

  GET_PARENT_NAICS_LIST: {
    service: "/naics",
    url: "/filter-hierarchy",
    method: "GET",
    authenticate: true,
  },

  GET_PARENT_AGENCY_LIST: {
    service: "/federal-agency",
    url: "/filter-list",
    method: "GET",
    authenticate: true,
  },
  GET_DUE_OPPORTUNITIES: {
    service: "/dashboard",
    url: "/opportunity",
    method: "GET",
    authenticate: true,
  },
  GET_PIPELINE_OVERVIEW: {
    service: "/dashboard",
    url: "/pipeline",
    method: "GET",
    authenticate: true,
  },
  GET_CARD_COUNT: {
    service: "/dashboard",
    url: "/cart",
    method: "GET",
    authenticate: true,
  },
  GET_FUNDING_STATUS: {
    service: "/naics",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  GET_NOTIFICATION: {
    service: "/notification",
    url: "/",
    method: "GET",
    authenticate: true,
  },
  UPDATE_NOTIFICATION_STATUS: {
    service: "/notification",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  MARK_ALL_READ_NOTIFICATION_STATUS: {
    service: "/notification",
    url: "/mark-all",
    method: "GET",
    authenticate: true,
  },
  EXPORT_CSV_AWARDS: {
    service: "/awards",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_CSV_FEDERALAGENCIES: {
    service: "/federal-agency",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_CSV_FEDERALPEOPLE: {
    service: "/federal-person",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_CSV_VENDOR: {
    service: "/vendors",
    url: "/export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_FOLLOWED_CSV_VENDOR: {
    service: "/vendors",
    url: "/followed-export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_FOLLOWED_CSV_OPPORTUNITY: {
    service: "/opportunity",
    url: "/followed-export",
    method: "GET",
    authenticate: true,
  },
  EXPORT_VIEW_OPPORTUNITY: {
    service: "/opportunity",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_AWARDS: {
    service: "/awards",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_FEDERALAGENCIES: {
    service: "/federal-agency",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_FEDERALPEOPLE: {
    service: "/federal-person",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_VENDOR: {
    service: "/vendors",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_NAICS: {
    service: "/naics",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  EXPORT_VIEW_PSC: {
    service: "/psc",
    url: "/sub-export-csv/:id",
    method: "POST",
    authenticate: true,
  },
  GET_GUESTDATA: {
    service: "/auth",
    url: "/guest-user",
    method: "GET",
    authenticate: true,
  },
};

export default ApiRoutes;
