/** @format */

export const DashboardInitialStates = {
  isLoading: false,
  opportunitiesDue: [],
  pipelineOverview: [],
  isPipelineLoading: false,
  isCountLoading: false,
  countData: {},
};
